import { query } from '@/graphql'

import { SpotCosting } from '.'

interface SpotCostingResponse {
  costPredictions: SpotCosting[]
}

const SPOT_COSTS_QUERY = `
  query GetCostPredictions($orderId: Int) {
    cost_predictions(where: {order_id: {_eq: $orderId}}) {
      order_id
      display_cost
      suggested_base_cost
      suggested_base_cost_incentives
      suggested_base_cost_octopus
      confidence_level
    }
  }
`

const UPDATE_SPOT_COSTS_MUTATION = `
  mutation update_display_cost($orderId: Int!, $displayCost: Boolean) {
    update_cost_predictions_by_pk (
        pk_columns: {
          order_id: $orderId
        }
    _set: {display_cost: $displayCost}
    ) {
    order_id
    display_cost
    }
  }
`

export class SpotCostingGqlService {
  private endpoint = ''
  private headers = {}

  constructor(
    url = process.env.VUE_APP_DATA_SERVICES_URL,
    key = process.env.VUE_APP_DATA_SERVICES_KEY
  ) {
    this.endpoint = url
    this.headers = { Authorization: key }
  }

  async fetchGqlExpectedAndAcceptNowCosts(orderId: number): Promise<SpotCosting> {
    const response = await query<SpotCostingResponse>(
      this.endpoint,
      this.headers,
      SPOT_COSTS_QUERY,
      {
        orderId
      }
    )

    return response?.costPredictions?.[0] ?? null
  }

  async updateGqlAcceptNowDisplayCost(orderId: number, displayCost: boolean) {
    await query(this.endpoint, this.headers, UPDATE_SPOT_COSTS_MUTATION, {
      orderId,
      displayCost
    })
  }
}
