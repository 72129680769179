import { AxiosResponse } from 'axios'

import { ASSIGNMENTS_API_URL, ASSIGNMENTS_API_AUDIENCE } from '@/config'
import {
  transformKeysToSnakeCase,
  transformKeysToCamelCase
} from '@/services/utils/casing'

import {
  CreateCharteringAssignmentRequest,
  CreateCarrierAssignmentRequest,
  ShipmentAssignmentsResponse
} from '../models'

import { httpClient, IHttpClient } from './http-client'

export interface IAssignmentsClient {
  getShipmentAssignments: (shipmentId: string) => Promise<ShipmentAssignmentsResponse>

  createCarrierAssignment: (request: CreateCarrierAssignmentRequest) => Promise<void>
  createCharteringAssignment: (
    request: CreateCharteringAssignmentRequest
  ) => Promise<void>
}

export class AssignmentsClient implements IAssignmentsClient {
  private baseUrl = () => `${ASSIGNMENTS_API_URL}/api`
  private audience = () => ASSIGNMENTS_API_AUDIENCE

  constructor(private client: IHttpClient) {}

  public getShipmentAssignments = async (
    shipmentId: string
  ): Promise<ShipmentAssignmentsResponse> => {
    const response = (await this.client.get(
      `${this.baseUrl()}/v2/shipments/${shipmentId}/assignments`,
      undefined,
      { apiGatewayAuthorization: true },
      this.audience(),
      'carrier_assignment:read'
    )) as AxiosResponse<unknown>
    return transformKeysToCamelCase<ShipmentAssignmentsResponse>(response.data)
  }

  public createCarrierAssignment = async ({
    carrierId,
    contactId,
    shipmentIds,
    type,
    acceptanceType,
    baseCost,
    validityHours,
    subsidiaryId
  }: CreateCarrierAssignmentRequest) => {
    await this.client.post(
      `${this.baseUrl()}/v2/carriers/${carrierId}/assignments`,
      transformKeysToSnakeCase({
        contactId,
        shipmentIds,
        type,
        acceptanceType,
        baseCost,
        validityHours,
        subsidiaryId
      }),
      { apiGatewayAuthorization: true },
      this.audience(),
      'carrier_assignment:create'
    )
  }

  public createCharteringAssignment = async ({
    shipmentIds,
    type,
    charteringOfficeId,
    baseCost
  }: CreateCharteringAssignmentRequest) => {
    await this.client.post(
      `${this.baseUrl()}/v2/chartering-offices/${charteringOfficeId}/assignments`,
      transformKeysToSnakeCase({
        shipmentIds,
        type,
        baseCost
      }),
      { apiGatewayAuthorization: true },
      this.audience(),
      'chartering_assignment:create'
    )
  }
}

export const assignmentsClient = new AssignmentsClient(httpClient)
