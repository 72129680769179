import { runJsonGet } from '@/models/backend-client'
import { SHIPMENT_INTERNAL_COMM_API_URL, USER_MANAGEMENT_API_AUDIENCE } from '@/config'
import { AxiosResponse } from 'axios'

import logger from '@/shell/console-logger'

export interface ShipmentInternalCommEntity {
  shipment_id: string
  front_link: string
}

export const getFrontLink = async (
  shipmentId: string
): Promise<ShipmentInternalCommEntity> => {
  try {
    const response = (await runJsonGet(
      `${SHIPMENT_INTERNAL_COMM_API_URL}/api/conversation/links/${shipmentId}`,
      undefined,
      { apiGatewayAuthorization: true },
      USER_MANAGEMENT_API_AUDIENCE,
      undefined
    )) as AxiosResponse<ShipmentInternalCommEntity>
    return {
      front_link: response.data?.front_link,
      shipment_id: response.data.shipment_id
    }
  } catch (error) {
    logger.error('Error fetching front link from shipment-internal-comm:', error)
    return { front_link: null, shipment_id: shipmentId }
  }
}
