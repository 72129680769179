var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Form',{ref:"form",attrs:{"disabled":_vm.disabled}},[_c('div',{staticClass:"plankton-grid"},[_c('div',{staticClass:"plankton-cell",attrs:{"span":"5"}},[_c('RadioButton',{attrs:{"id":_vm.PALLET_EXCHANGE_TYPES.NOT_REQUIRED,"option":{
          value: _vm.type,
          label: _vm.PALLET_EXCHANGE_NAMES[_vm.PALLET_EXCHANGE_TYPES.NOT_REQUIRED],
          disabled: _vm.disabled
        },"value":_vm.PALLET_EXCHANGE_TYPES.NOT_REQUIRED},on:{"change":function($event){return _vm.$emit('update:type', $event)}}})],1),_c('div',{staticClass:"plankton-cell",attrs:{"span":"7"}},[_c('RadioButton',{attrs:{"id":_vm.PALLET_EXCHANGE_TYPES.NOT_FEASIBLE_FOR_CARRIER,"value":_vm.PALLET_EXCHANGE_TYPES.NOT_FEASIBLE_FOR_CARRIER,"option":{
          value: _vm.type,
          label: _vm.PALLET_EXCHANGE_NAMES[_vm.PALLET_EXCHANGE_TYPES.NOT_FEASIBLE_FOR_CARRIER],
          disabled: _vm.disabled
        }},on:{"change":function($event){return _vm.$emit('update:type', $event)}}})],1),_c('div',{staticClass:"plankton-cell",attrs:{"span":"3"}},[_c('RadioButton',{attrs:{"id":_vm.PALLET_EXCHANGE_TYPES.DPL_STANDORT,"value":_vm.PALLET_EXCHANGE_TYPES.DPL_STANDORT,"option":{
          value: _vm.type,
          label: _vm.PALLET_EXCHANGE_NAMES[_vm.PALLET_EXCHANGE_TYPES.DPL_STANDORT],
          disabled: _vm.disabled
        }},on:{"change":function($event){return _vm.$emit('update:type', $event)}}})],1),_c('div',{staticClass:"plankton-cell",staticStyle:{"display":"flex"},attrs:{"span":"8"}},[_c('PalletExchangeAddressDropdown',{staticClass:"pallet-exchange-addresses",staticStyle:{"margin-top":"-30px"},attrs:{"value":_vm.addressId,"disabled":!_vm.addressListEnabled || _vm.disabled},on:{"input":function($event){return _vm.$emit('update:addressId', $event)}}})],1)]),_c('div',{staticClass:"plankton-grid",staticStyle:{"margin-top":"-10px"}},[_c('div',{staticClass:"plankton-cell",attrs:{"span":"5"}},[_c('RadioButton',{attrs:{"id":_vm.PALLET_EXCHANGE_TYPES.REGULAR_PALLET_EXCHANGE,"value":_vm.PALLET_EXCHANGE_TYPES.REGULAR_PALLET_EXCHANGE,"option":{
          value: _vm.type,
          label: _vm.PALLET_EXCHANGE_NAMES[_vm.PALLET_EXCHANGE_TYPES.REGULAR_PALLET_EXCHANGE],
          disabled: _vm.disabled
        }},on:{"change":function($event){return _vm.$emit('update:type', $event)}}})],1),_c('div',{staticClass:"plankton-cell",attrs:{"span":"7"}},[_c('RadioButton',{attrs:{"id":_vm.PALLET_EXCHANGE_TYPES.DPL_VIRTUAL,"value":_vm.PALLET_EXCHANGE_TYPES.DPL_VIRTUAL,"option":{
          value: _vm.type,
          label: _vm.PALLET_EXCHANGE_NAMES[_vm.PALLET_EXCHANGE_TYPES.DPL_VIRTUAL],
          disabled: _vm.disabled
        }},on:{"change":function($event){return _vm.$emit('update:type', $event)}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }