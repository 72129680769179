<template>
  <div>
    <AccountManagerAssignments
      v-if="isAssignmentsManagementEnabled"
      data-test="aom-assignments"
      ref="accountManagerAssignmentsRef"
      :order="order"
      :orderStops="orderStops"
      :hasBeenCarrierLocked="hasBeenCarrierLocked"
      :spotCosting="spotCosting"
      :offerList="accountManagerOfferList"
      :shipmentId="shipmentId"
    />
    <CarrierManagerAssignments
      v-else
      data-test="cm-assignments"
      ref="carrierManagerAssignmentsRef"
      :order="order"
      :orderStops="orderStops"
      :hasBeenCarrierLocked="hasBeenCarrierLocked"
      :spotCosting="spotCosting"
      :offerList="carrierAllocationOffers"
    />
    <BidsPanelComponent data-test="bids-panel" />
    <OrderStageFooter
      :accountManager="accountManager"
      :juniorAccountManager="juniorAccountManager"
      :spotBidder="spotBidder"
      :partnerManager="partnerManager"
      :accountManagerDisabled="true"
      :partnerManagerDisabled="true"
      :orderRegularity="order.regularity"
      :orderState="order.state"
    >
      <template #left-actions>
        <TeamAssignmentTrigger class="field" openDirection="top" :orderId="order.id" />
      </template>
      <template #actions>
        <CarrierSuggestionsButton
          v-if="isSennderOrPoste"
          :order="order"
          :stops="stops"
          :disabled="disabled"
          category="tertiary"
          @offers-sent="fetchCompleteOrder(order.id)"
        />
        <NextLoadsButton
          data-test="next-loads-button"
          :orderId="order.id"
          v-if="isSennderOrPoste && !hideNextLoadsButton"
        />
        <AssignmentsButton
          v-if="isAssignmentsButtonShown"
          class="action-button"
          data-test="assign-carrier-button"
          :isReplatformizedOrder="isReplatformizedOrder"
          :selectedIds="selectedIds"
          @onSubmit="onSubmit"
        />
        <SubmitNewBid data-test="submit-new-bid-button" />
      </template>
    </OrderStageFooter>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import orderingStoreMappers from '@/modules/ordering/store/mappers'
import OrderStageFooter from '@/modules/ordering/components/shared/OrderStageFooter'
import TeamAssignmentTrigger from '@/modules/ordering/components/shared/TeamAssignmentTrigger'
import { humanizeIsoDateTimeWithDots } from '@sennder/plankton'

import NextLoadsButton from './NextLoadsButton'
import { formatMoneyWithDashForNull } from '@/global-setup/filters'
import CarrierSuggestionsButton from './carrier-suggestions/CarrierSuggestionsButton'
import useStore from '@/compositions/useStore'
import useCurrentUser from '@/compositions/useCurrentUser'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { hasHadState } from '@/modules/common/order-states'

import { isSennderTheme, isPosteTheme } from '@/controllers/environment-detection'
import AssignmentsButton from '@/microfrontends/pages/assignments-button/AssignmentsButton.vue'
import { AccountManagerAssignments } from '@/modules/assignments'
import { useAssignmentsStatus } from '@/modules/assignments/compositions'

import BidsPanelComponent from '@/microfrontends/pages/bidding/BidsPanelComponent.vue'
import { CarrierManagerAssignments } from '@/modules/assignments'
import SubmitNewBid from '@/microfrontends/pages/bidding/SubmitNewBid.vue'

import {
  ENABLE_CM_MANAGE_ASSIGNMENTS,
  ENABLE_AOM_MANAGE_ASSIGNMENTS,
  HIDE_NEXT_LOADS_BUTTON
} from './constants.ts'

export default {
  components: {
    OrderStageFooter,
    NextLoadsButton,
    CarrierSuggestionsButton,
    TeamAssignmentTrigger,
    AccountManagerAssignments,
    AssignmentsButton,
    CarrierManagerAssignments,
    SubmitNewBid,
    BidsPanelComponent
  },
  props: {
    order: { type: Object, required: true },
    stops: { type: Array, required: true }
  },
  setup() {
    const { currentUserIsAccountManager, currentUserIsPartnerManager } = useCurrentUser()
    const { isActive } = useFeatureFlag()
    const enableCMManageAssignments = isActive(ENABLE_CM_MANAGE_ASSIGNMENTS)
    const enableAOMManageAssignments = isActive(ENABLE_AOM_MANAGE_ASSIGNMENTS)
    const hideNextLoadsButton = isActive(HIDE_NEXT_LOADS_BUTTON)

    const store = useStore()
    const orderLogs = ref(null)
    const someOffersCantBeAccepted = ref(false)

    const accountManagerOfferList = computed(() => [])
    const assignments = computed(() => store.state.assignments.assignments)

    const { hasPendingAssignment, hasAcceptedAssignment, containsLockedAssignments } =
      useAssignmentsStatus()

    const partnerManagerOfferList = computed(() => [])

    const isAssignmentsManagementEnabled = computed(
      () =>
        (enableAOMManageAssignments.value && currentUserIsAccountManager.value) ||
        (enableCMManageAssignments.value && currentUserIsPartnerManager.value)
    )
    const isAssignmentsButtonShown = computed(
      () =>
        isAssignmentsManagementEnabled.value &&
        !hasPendingAssignment.value &&
        !hasAcceptedAssignment.value &&
        !containsLockedAssignments.value
    )

    return {
      orderLogs,
      someOffersCantBeAccepted,
      accountManagerOfferList,
      assignments,
      hasPendingAssignment,
      hasAcceptedAssignment,
      containsLockedAssignments,

      partnerManagerOfferList,

      currentUserIsAccountManager,
      currentUserIsPartnerManager,

      isAssignmentsButtonShown,

      isAssignmentsManagementEnabled,
      hideNextLoadsButton
    }
  },

  computed: {
    ...mapGetters(['currentUserId', 'doesUserHavePermission']),
    ...orderingStoreMappers.mapState([
      'accountManager',
      'juniorAccountManager',
      'partnerManager',
      'spotBidder',
      'partnerManager',
      'orderStops',
      'spotCosting',
      'shipmentData'
    ]),
    ...orderingStoreMappers.mapGetters([
      'hasAcceptedAssignment',
      'pendingAssignment',
      'carrierKpi',
      'hasHadState'
    ]),
    carrierAllocationOffers() {
      const carrierAllocationsFromOffers = this.partnerManagerOfferList
      return carrierAllocationsFromOffers
    },
    shipmentId() {
      return this.shipmentData && this.shipmentData.length
        ? this.shipmentData[0]?.id
        : null
    },
    isReplatformizedOrder() {
      return !!this.order.workflowManaged
    },
    selectedIds() {
      return this.shipmentData && this.shipmentData.length
        ? this.shipmentData.map(x => x.id)
        : [this.order.id]
    },
    hasBeenCarrierLocked() {
      return hasHadState(this.order.state, 'CARRIER_LOCKED')
    },
    disabled() {
      return this.order.state !== 'REGISTERED'
    },
    isSennderOrPoste() {
      return isSennderTheme || isPosteTheme
    }
  },
  methods: {
    humanizeIsoDateTimeWithDots,
    formatMoneyWithDashForNull,
    ...orderingStoreMappers.mapActions(['fetchCompleteOrder']),
    onSubmit() {
      this.$refs.accountManagerAssignmentsRef?.onSubmit()
    },
    assignCarrier() {
      this.$refs.accountManagerAssignmentsRef?.assignCarrier()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 32px 24px;
}

.assignment-date-container {
  color: $color-neutral-main;
}

.allocation-offer-list {
  padding-top: 20px;
}

.assignment-info-tooltip {
  font-size: 15px;
  cursor: pointer;
  margin-left: 2px;

  &:hover {
    color: $color-secondary-main;
  }
}

.expected-percentage {
  margin-left: 4px;
}

.margin-ok,
.target-deviation-ok {
  color: $color-success-main;
}

.margin-error,
.target-deviation-error {
  color: $color-error-main;
}

.bid-info-alert,
.bid-delay-info-alert,
.assignments-locked-info-alert {
  width: fit-content;
  margin-bottom: 2em;
}

.percentage-label {
  color: $color-neutral-main;
}

.plankton-cell.pex-cell {
  display: flex;
  align-items: center;
}

.action-button {
  display: inline-block;
  margin-left: 10px;
  text-align: left;
}
</style>
