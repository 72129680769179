import { MutationTree } from 'vuex'

import { CarrierKpi, CharteringOffice, InternalAssignment } from '../models'

import { IAssignmentsState } from './state'

export type SetCarriersKpiMutation = (
  state: IAssignmentsState,
  carriersKpi: CarrierKpi[]
) => void
export const setCarriersKpi: SetCarriersKpiMutation = (state, carriersKpi) => {
  state.carriersKpi = carriersKpi
}

export type SetAssignmentsMutation = (
  state: IAssignmentsState,
  assignments: InternalAssignment[]
) => void
export const setAssignments: SetAssignmentsMutation = (state, assignments) => {
  state.assignments = assignments
}

export type SetCharteringOfficesMutation = (
  state: IAssignmentsState,
  charteringOffices: CharteringOffice[]
) => void
export const setCharteringOffices: SetCharteringOfficesMutation = (
  state,
  charteringOffices
) => {
  state.charteringOffices = charteringOffices
}

export type SetCarrierTrackingRateMutation = (
  state: IAssignmentsState,
  {
    carrierId,
    rate
  }: {
    carrierId: number
    rate: number
  }
) => void
export const setCarrierTrackingRate: SetCarrierTrackingRateMutation = (
  state,
  { carrierId, rate }
): void => {
  state.carriersTrackingRate[carrierId] = rate
}

export type MutationsType = {
  setCarriersKpi: SetCarriersKpiMutation
  setAssignments: SetAssignmentsMutation
  setCharteringOffices: SetCharteringOfficesMutation
  setCarrierTrackingRate: SetCarrierTrackingRateMutation
}

export const mutations: MutationTree<IAssignmentsState> & MutationsType = {
  setCarriersKpi,
  setAssignments,
  setCharteringOffices,
  setCarrierTrackingRate
}
