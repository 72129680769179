import Vue from 'vue'
import Vuex from 'vuex'
import { createInitialState } from './create-initial-state'
import { actions } from './actions'
import { mutations } from './mutations'

import { store as common, CommonStore, ICommonState } from '@/modules/common/store'
import {
  store as assignments,
  AssignmentsStore,
  IAssignmentsState
} from '@/modules/assignments/store'
import { store as ordering } from '@/modules/ordering/store'
import { store as shipment } from '@/modules/shipment/store'
import { default as esExecutionBoard } from '@/modules/es-execution-board/store'
import { store as financeBoard } from '@/modules/finance-board'
import { store as requestBoard } from '@/modules/request-board'
import { default as searchBoard } from '@/modules/search-board/store'
import { store as auth } from '@/modules/auth'

Vue.use(Vuex)
Vue.config.devtools = true

export type RootState = {
  assignments: IAssignmentsState
  common: ICommonState
  // TODO: remove when every store is going to be typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export type Store = AssignmentsStore<Pick<RootState, 'assignments'>> &
  CommonStore<Pick<RootState, 'common'>>

export const storeConfiguration = () => ({
  state: () => createInitialState(),
  mutations,
  actions,
  modules: {
    common,
    assignments,
    auth,
    ordering,
    shipment,
    esExecutionBoard,
    financeBoard,
    requestBoard,
    searchBoard
  }
})

export const store = new Vuex.Store(storeConfiguration())
