import * as orderAssignmentService from '@/services/order-assignment-service'

import { createCarrierAssignmentToBeDepreciated } from '@/services/order-assignment-service'

import {
  AcceptanceType,
  AssignmentType,
  InternalAssignment,
  MothershipAssignmentsResponse,
  mapMothershipAssignment,
  mapPlannedMothershipAssignment,
  mapPlannedShipmentAssignments,
  mapShipmentAssignments
} from '../models'

import { IAssignmentsClient, assignmentsClient } from './assignments-client'

interface AssignmentRequest {
  orderId?: number
  basePrice: number
  shipmentId?: string
}

interface CreateCarrierAssignmentModel {
  carrierId: string
  contactId: string
  orderId: number
  shipmentId: string
  baseCost: number
  validityHours: number
  subsidiaryId?: number
}

export interface IAssignmentsService {
  getAssignments: (request: AssignmentRequest) => Promise<InternalAssignment[]>
  createCarrierAssignment: (model: CreateCarrierAssignmentModel) => Promise<void>
  createCharteringAssignment: (
    shipmentId: string,
    charteringOfficeId: string,
    baseCost: number
  ) => Promise<void>
}

export class AssignmentsService implements IAssignmentsService {
  constructor(private client: IAssignmentsClient) {}
  public getAssignments = async ({
    orderId,
    shipmentId,
    basePrice
  }: AssignmentRequest): Promise<InternalAssignment[]> => {
    const shouldUseNewEndpoints = !!shipmentId

    if (shouldUseNewEndpoints) {
      const assignmentsResponse = await this.client.getShipmentAssignments(shipmentId)

      return [
        ...mapShipmentAssignments(assignmentsResponse.done, basePrice),
        ...mapPlannedShipmentAssignments(assignmentsResponse.planned, basePrice)
      ]
    }

    const mothershipAssignmentResponse = (
      orderId
        ? await orderAssignmentService.loadCarrierAssignmentsToBeDepreciated(orderId)
        : { done: [], planned: [] }
    ) as MothershipAssignmentsResponse

    return [
      ...mapMothershipAssignment(mothershipAssignmentResponse.done),
      ...mapPlannedMothershipAssignment(mothershipAssignmentResponse.planned)
    ]
  }

  public createCharteringAssignment = async (
    shipmentId: string,
    charteringOfficeId: string,
    baseCost: number
  ): Promise<void> => {
    await this.client.createCharteringAssignment({
      shipmentIds: [shipmentId],
      type: AssignmentType.MANUAL,
      charteringOfficeId,
      baseCost
    })
  }

  public createCarrierAssignment = async ({
    carrierId,
    contactId,
    orderId,
    shipmentId,
    baseCost,
    validityHours,
    subsidiaryId
  }: CreateCarrierAssignmentModel): Promise<void> => {
    const shouldUseNewEndpoints = !!shipmentId

    if (shouldUseNewEndpoints) {
      await this.client.createCarrierAssignment({
        carrierId,
        contactId,
        shipmentIds: [shipmentId],
        type: AssignmentType.MANUAL,
        acceptanceType: AcceptanceType.MANUALLY_ACCEPTED,
        baseCost,
        validityHours,
        subsidiaryId
      })
    } else {
      await createCarrierAssignmentToBeDepreciated({
        orderId,
        carrierId,
        carrierContactId: contactId,
        baseCost,
        validity: validityHours
      })
    }
  }
}

export const assignmentsService = new AssignmentsService(assignmentsClient)
