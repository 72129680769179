<template>
  <div :class="{ 'root--bold': bold }" class="plankton-cell paragraph root">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    bold: { type: Boolean, default: false }
  }
}
</script>
<style lang="scss" scoped>
.root.plankton-cell {
  color: $color-neutral-main;
  display: flex;
  align-items: center;

  &.root--bold {
    color: $color-neutral-darkest;
  }

  p {
    margin: 0;
  }
}
</style>
