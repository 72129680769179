import { runJsonGet, runJsonPost } from '@/models/backend-client'
import { assignOrdersToCarrierToBeDepreciated } from './dispatching-board-service'
import { OPERATIONS_BACKEND_URL, ASSIGNMENTS_API_URL } from '@/config'
import { transformKeysToCamelCase } from './utils/casing'

export const loadCarrierAssignmentsToBeDepreciated = async orderId => {
  const result = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/order-assignments/queries/get-order-assignments-info/${orderId}`
  )

  return transformKeysToCamelCase(result.data)
}

export const loadCarrierAssignmentsDetails = async orderIds => {
  const result = await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/order-assignments/queries/get-order-assignment-details`,
    { order_ids: orderIds }
  )

  return transformKeysToCamelCase(result.data)
}

export const createCarrierAssignmentToBeDepreciated = async assignment => {
  const assignmentIds = await assignOrdersToCarrierToBeDepreciated({
    orderIds: [assignment.orderId],
    carrierId: assignment.carrierId,
    contactIds: [assignment.carrierContactId],
    validityHours: assignment.validity,
    baseCost: assignment.baseCost
  })

  return assignmentIds[0]
}

export const cancelCarrierAssignmentToBeDepreciated = async assignmentId => {
  await runJsonPost(
    `${OPERATIONS_BACKEND_URL}/order-assignments/commands/cancel-assignment/${assignmentId}`
  )
}

export const cancelCarrierAssignment = async assignmentId => {
  await runJsonPost(
    `${ASSIGNMENTS_API_URL}/api/assignments/cancel-assignment`,
    {
      assignment_id: assignmentId
    },
    { apiGatewayAuthorization: true },
    'https://api.cloud.sennder.com/carrier-assign',
    'carrier_assignment:cancel'
  )
}
