import { CarrierKpi, InternalAssignment, CharteringOffice } from '../models'

export interface IAssignmentsState {
  assignments: InternalAssignment[]
  carriersKpi: CarrierKpi[]
  charteringOffices: CharteringOffice[]
  carriersTrackingRate?: Record<number, number>
}

const getInitialState: () => IAssignmentsState = () => ({
  assignments: [],
  carriersKpi: [],
  charteringOffices: [],
  carriersTrackingRate: {}
})

export default getInitialState
