import { computed, ref } from '@vue/composition-api'

import { Currency } from '@/modules/common'
import useStore, { Store } from '@/compositions/useStore'
import { getCurrencyIcon } from '@/global-setup/filters'

import { CPSCarrierContact, CarrierModel } from '../models'
import { useSubsidiaries } from './useSubsidiaries'
import { carrierProfileService } from '../services'

export const useCarrierForm = () => {
  const store = useStore() as Store

  const subsidiaryCurrency = ref(Currency.EUR)

  const { getDefaultSubsidiary, getMainSubsidiary } = useSubsidiaries()

  const makeCarrierSubsidiaryFallback = (carrier: CarrierModel) =>
    carrier.sennderSubsidiaryId
      ? getMainSubsidiary([carrier.sennderSubsidiaryId])
      : getDefaultSubsidiary()

  const getCarrierSubsidiaryCurrency = (carrier: CarrierModel) => {
    return makeCarrierSubsidiaryFallback(carrier).paymentCurrency
  }

  const getCarrierById = (id: string) => {
    return carrierProfileService.getCarrier(id)
  }

  const addCarrierAssignment = async ({
    orderId,
    carrier,
    contact,
    baseCost,
    validityHours
  }: {
    orderId: number
    carrier: CarrierModel
    contact: CPSCarrierContact
    baseCost: number
    validityHours: number
  }) => {
    const subsidiary = makeCarrierSubsidiaryFallback(carrier)

    await store.dispatch('assignments/createCarrierAssignment', {
      orderId,
      carrierId: carrier.id,
      contactId: contact.id,
      baseCost,
      validityHours,
      subsidiaryId: subsidiary.id
    })
  }

  const subsidiaryCurrencyIcon = computed(() => getCurrencyIcon(subsidiaryCurrency.value))

  return {
    subsidiaryCurrency,
    subsidiaryCurrencyIcon,

    addCarrierAssignment,
    getCarrierById,
    getCarrierSubsidiaryCurrency
  }
}
