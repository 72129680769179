import { getTenant } from '@/controllers/environment-detection'
import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'

export const useTenants = () => {
  const tenant = getTenant()

  const isSennderTenant = () => tenant === Tenant.SENNDER
  const isPosteTenant = () => tenant === Tenant.POSTE

  return {
    tenant,

    isSennderTenant,
    isPosteTenant
  }
}
