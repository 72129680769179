export const OFFER_LIST_REFRESH_RATE = 60000 // 1min in ms

export const ASSIGNMENTS_FEATURE_FLAGS = {
  ENABLE_MOTHERSHIP_ASSIGNMENTS: 'ENABLE_MOTHERSHIP-ASSIGNMENTS',
  ENABLE_ORDER_DETAILS_TARGET_DEVIATION: 'ENABLE_ORDER-DETAILS-TARGET-DEVIATION',
  ENABLE_ASSIGNMENTS_TRACKING_RATE: 'ENABLE_ASSIGNMENTS-TRACKING-RATE'
}

export const ASSIGNMENT_TYPES = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC'
}

export const CARRIER_ASSIGNMENT_STATES = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  NOT_ASSIGNED: 'NOT_ASSIGNED',
  NO_CAPACITY: 'NO_CAPACITY',
  CARRIER_BLACKLISTED: 'CARRIER_BLACKLISTED',
  AWAITING_MATCHING_CONFIRMATION: 'AWAITING_MATCHING_CONFIRMATION'
}

export const CARRIER_OFFER_STATES = {
  CREATED: 'CREATED',
  WITHDRAWN: 'WITHDRAWN',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED'
}
