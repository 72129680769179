import { onUnmounted } from '@vue/composition-api'

export default (startCallback = () => {}, stopCallback = () => {}) => {
  const handleVisibilityChange = () => {
    if (document.hidden) {
      stopCallback()
    } else {
      startCallback()
    }
  }

  startCallback()
  document.addEventListener('visibilitychange', handleVisibilityChange, false)

  onUnmounted(() => {
    stopCallback()
    document.removeEventListener('visibilitychange', handleVisibilityChange, false)
  })
}
