export const permissions = [
  'intimeadapter.change_intimelocationtracking',
  'ordering.add_order',
  'infrastructure.add_transferstepstemplate',
  'contract_management.can_persist_contract_matches',
  'ordering.add_ordertemplate',
  'ordering.change_ordertemplate',
  'intimeadapter.delete_intimelocationtracking',
  'ordering.delete_ordergroup',
  'contract_management.view_persistcontractmatchespermissions',
  'finance.can_edit_cost_centre',
  'ordering.can_edit_order_details_after_dispatched',
  'ordering.change_order',
  'gatehouse_push.delete_gatehousetracking',
  'contract_management.add_persistcontractmatchespermissions',
  'ordering.delete_order',
  'finance.can_view_cost_centre',
  'ordering.change_ordergroup',
  'infrastructure.change_transferstepstemplate',
  'notification_center.can_receive_notifications',
  'gatehouse_push.change_gatehousetracking',
  'infrastructure.add_transfersteptemplate',
  'intimeadapter.add_intimelocationtracking',
  'gatehouse_push.add_gatehousetracking',
  'proof_of_delivery.add_proofofdeliverydocument',
  'infrastructure.delete_transferstepstemplate',
  'ordering.read_shippers',
  'infrastructure.delete_transfersteptemplate',
  'accounts.CanAddCarrierSurcharges',
  'ordering.read_carriers',
  'accounts.CanAddCustomerSurcharges',
  'ordering.can_add_carrier_surcharges',
  'contract_management.delete_persistcontractmatchespermissions',
  'ordering.add_ordergroup',
  'finance.can_edit_order_cost_centre',
  'user_management.can_view_team_page',
  'finance.can_view_order_cost_centre',
  'ordering.can_edit_pickup_date_without_limitations',
  'ordering.can_add_customer_surcharges',
  'contract_management.change_persistcontractmatchespermissions',
  'ordering.delete_ordertemplate',
  'ordering.can_get_order_template_detail',
  'ordering.can_edit_stopovers',
  'infrastructure.change_transfersteptemplate',
  'ordering.can_copy_order',
  'https://api.cloud.sennder.com/accounting:carrier-surcharges:read',
  'https://api.cloud.sennder.com/accounting:carrier-surcharges:write',
  'https://api.cloud.sennder.com/activity-records:activity-records:read-any',
  'https://api.cloud.sennder.com/activity-records:activity-records:read-any',
  'https://api.cloud.sennder.com/ca-confirmation-email:confirmation-email:read',
  'https://api.cloud.sennder.com/ca-confirmation-email:confirmation-email:trigger',
  'https://api.cloud.sennder.com/ca-confirmation-email:confirmation-email:trigger',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:accept',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:accept',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:cancel',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:cancel',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:create',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:create',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:read',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:read',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:reject',
  'https://api.cloud.sennder.com/carrier-assign:carrier_assignment:reject',
  'https://api.cloud.sennder.com/carrier-assign:chartering_assignment:create',
  'https://api.cloud.sennder.com/carrier-fin-docs:fin-docs:read',
  'https://api.cloud.sennder.com/carrier-fin-docs:fin-docs:read',
  'https://api.cloud.sennder.com/carrier-preferences:carriers:create-lane-preferences',
  'https://api.cloud.sennder.com/carrier-preferences:carriers:create-lane-preferences',
  'https://api.cloud.sennder.com/carrier-preferences:carriers:read-lane-preferences',
  'https://api.cloud.sennder.com/carrier-preferences:carriers:read-lane-preferences',
  'https://api.cloud.sennder.com/carrier-profile:carriers:create',
  'https://api.cloud.sennder.com/carrier-profile:carriers:create-contacts',
  'https://api.cloud.sennder.com/carrier-profile:carriers:download-documents',
  'https://api.cloud.sennder.com/carrier-profile:carriers:download-documents',
  'https://api.cloud.sennder.com/carrier-profile:carriers:list',
  'https://api.cloud.sennder.com/carrier-profile:carriers:list',
  'https://api.cloud.sennder.com/carrier-profile:carriers:list-addresess',
  'https://api.cloud.sennder.com/carrier-profile:carriers:list-contacts',
  'https://api.cloud.sennder.com/carrier-profile:carriers:list-contacts',
  'https://api.cloud.sennder.com/carrier-profile:carriers:list-documents',
  'https://api.cloud.sennder.com/carrier-profile:carriers:list-documents',
  'https://api.cloud.sennder.com/carrier-profile:carriers:read',
  'https://api.cloud.sennder.com/carrier-profile:carriers:read',
  'https://api.cloud.sennder.com/carrier-profile:carriers:read-addresess',
  'https://api.cloud.sennder.com/carrier-profile:carriers:read-contacts',
  'https://api.cloud.sennder.com/carrier-profile:carriers:read-contacts',
  'https://api.cloud.sennder.com/carrier-profile:carriers:read-documents',
  'https://api.cloud.sennder.com/carrier-profile:carriers:read-documents',
  'https://api.cloud.sennder.com/carrier-profile:carriers:update',
  'https://api.cloud.sennder.com/carrier-profile:carriers:update-contacts',
  'https://api.cloud.sennder.com/carrier-profile:carriers:upload-documents',
  'https://api.cloud.sennder.com/comments-and-activity:comments:write',
  'https://api.cloud.sennder.com/comments-and-activity:comments:write',
  'https://api.cloud.sennder.com/contractmanagement:contracts:import',
  'https://api.cloud.sennder.com/contractmanagement:contracts:read-any',
  'https://api.cloud.sennder.com/contractmanagement:contracts:read-any',
  'https://api.cloud.sennder.com/do-api:operators:assign',
  'https://api.cloud.sennder.com/do-api:operators:get',
  'https://api.cloud.sennder.com/do-api:operators:get',
  'https://api.cloud.sennder.com/facility-mgmt:plans:read',
  'https://api.cloud.sennder.com/facility-mgmt:plans:update',
  'https://api.cloud.sennder.com/facility-mgmt:profiles:read',
  'https://api.cloud.sennder.com/facility-mgmt:profiles:write',
  'https://api.cloud.sennder.com/fleet:asset-allocations:read-all',
  'https://api.cloud.sennder.com/fleet:asset-allocations:read-all',
  'https://api.cloud.sennder.com/fleet:asset-allocations:write-all',
  'https://api.cloud.sennder.com/fleet:asset-allocations:write-all',
  'https://api.cloud.sennder.com/fleet:asset_plan:write',
  'https://api.cloud.sennder.com/fleet:asset_plan:write',
  'https://api.cloud.sennder.com/fleet:carriers:read-all',
  'https://api.cloud.sennder.com/fleet:carriers:read-all',
  'https://api.cloud.sennder.com/fleet:drivers:read-all',
  'https://api.cloud.sennder.com/fleet:drivers:read-all',
  'https://api.cloud.sennder.com/fleet:drivers:write-all',
  'https://api.cloud.sennder.com/fleet:drivers:write-all',
  'https://api.cloud.sennder.com/fleet:orders:read-any',
  'https://api.cloud.sennder.com/fleet:orders:read-any',
  'https://api.cloud.sennder.com/fleet:trailers:read-all',
  'https://api.cloud.sennder.com/fleet:trailers:read-all',
  'https://api.cloud.sennder.com/fleet:trailers:write-all',
  'https://api.cloud.sennder.com/fleet:trailers:write-all',
  'https://api.cloud.sennder.com/fleet:trucks:read-all',
  'https://api.cloud.sennder.com/fleet:trucks:read-all',
  'https://api.cloud.sennder.com/fleet:trucks:write-all',
  'https://api.cloud.sennder.com/fleet:trucks:write-all',
  'https://api.cloud.sennder.com/incident-management:im:read',
  'https://api.cloud.sennder.com/incident-management:im:write',
  'https://api.cloud.sennder.com/octopus-bidding-bff:available-bidding-actions:ops-read',
  'https://api.cloud.sennder.com/octopus-bidding-bff:available-bidding-actions:ops-read',
  'https://api.cloud.sennder.com/octopus-bidding-bff:bids:accept',
  'https://api.cloud.sennder.com/octopus-bidding-bff:bids:accept',
  'https://api.cloud.sennder.com/octopus-bidding-bff:bids:confirm',
  'https://api.cloud.sennder.com/octopus-bidding-bff:bids:create',
  'https://api.cloud.sennder.com/octopus-bidding-bff:bids:read',
  'https://api.cloud.sennder.com/octopus-bidding-bff:bids:read',
  'https://api.cloud.sennder.com/octopus-bidding-bff:bids:reject',
  'https://api.cloud.sennder.com/octopus-bidding-bff:bids:reject',
  'https://api.cloud.sennder.com/octopus-bidding-bff:bids:withdraw-own',
  'https://api.cloud.sennder.com/octopus-bidding-bff:calculations:read',
  'https://api.cloud.sennder.com/octopus-bidding-bff:carriers:list',
  'https://api.cloud.sennder.com/octopus-bidding-bff:carriers:read-contacts',
  'https://api.cloud.sennder.com/octopus-bidding-bff:manual-suggested-base-cost:ops-edit',
  'https://api.cloud.sennder.com/octopus-bidding-bff:manual-suggested-base-cost:ops-read',
  'https://api.cloud.sennder.com/oras:operator-roles:assign',
  'https://api.cloud.sennder.com/oras:operator-roles:get',
  'https://api.cloud.sennder.com/oras:operator-roles:get',
  'https://api.cloud.sennder.com/pallet-exchange:read',
  'https://api.cloud.sennder.com/pallet-exchange:write',
  'https://api.cloud.sennder.com/pod-upload:pod:read',
  'https://api.cloud.sennder.com/pod-upload:pod:read',
  'https://api.cloud.sennder.com/pod-upload:pod:read-all',
  'https://api.cloud.sennder.com/pod-upload:pod:read-all',
  'https://api.cloud.sennder.com/pod-upload:pod:read-all',
  'https://api.cloud.sennder.com/search-proxy:search:any',
  'https://api.cloud.sennder.com/search-query-api:search:any',
  'https://api.cloud.sennder.com/settlement:carrier-cost:read',
  'https://api.cloud.sennder.com/settlement:carrier-cost:read',
  'https://api.cloud.sennder.com/settlement:carrier-cost:write',
  'https://api.cloud.sennder.com/settlement:diesel-floater:write',
  'https://api.cloud.sennder.com/settlement:shipper-price:read',
  'https://api.cloud.sennder.com/settlement:shipper-price:read',
  'https://api.cloud.sennder.com/settlement:shipper-price:write',
  'https://api.cloud.sennder.com/shipment-opportunities:shipment-opportunities:read',
  'https://api.cloud.sennder.com/shipment-opportunities:shipment-opportunities:read',
  'https://api.cloud.sennder.com/shipment-opportunities:shipment-opportunities:read',
  'https://api.cloud.sennder.com/shipments:shipments:create',
  'https://api.cloud.sennder.com/shipments:shipments:create',
  'https://api.cloud.sennder.com/shipments:shipments:read',
  'https://api.cloud.sennder.com/spot-opportunities:spot-opportunities:read-any',
  'https://api.cloud.sennder.com/spot-opportunities:spot-opportunities:read-any',
  'https://api.cloud.sennder.com/spot-opportunities:spot-opportunities:read-any',
  'https://api.cloud.sennder.com/transit-services:transit:post-execution-write',
  'https://api.cloud.sennder.com/transit-services:transit:post-execution-write',
  'https://api.cloud.sennder.com/transit-services:transit:read',
  'https://api.cloud.sennder.com/transit-services:transit:write',
  'https://api.cloud.sennder.com/transport-planning:commands:execute',
  'https://api.cloud.sennder.com/transport-planning:commands:execute',
  'https://api.cloud.sennder.com/transport-planning:transport-allocations:read-all',
  'https://api.cloud.sennder.com/transport-planning:transport-allocations:read-all',
  'https://api.cloud.sennder.com/usermanagement:users:read-any',
  'https://api.cloud.sennder.com/vetting-v2:vettings:blocklisting-list',
  'https://api.cloud.sennder.com/vetting-v2:vettings:blocklisting-read',
  'https://api.cloud.sennder.com/vetting-v2:vettings:blocklisting-submit',
  'https://api.cloud.sennder.com/vetting-v2:vettings:carrier-information-update-submit',
  'https://api.cloud.sennder.com/vetting-v2:vettings:de-blocklisting-submit',
  'https://api.cloud.sennder.com/vetting-v2:vettings:list',
  'https://api.cloud.sennder.com/vetting-v2:vettings:read',
  'https://api.cloud.sennder.com/vetting-v2:vettings:read-data',
  'https://api.cloud.sennder.com/vetting-v2:vettings:submit',
  'https://api.cloud.sennder.com/workflow-interface:feasibility-workflow:post',
  'https://api.sennder.com:orders:read-any',
  'https://api.sennder.com:orders:read-any',
  'https://api.sennder.com:transfers:update-any',
  'https://api.sennder.com:transfers:update-any'
]
