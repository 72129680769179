<template>
  <Dialog :show="visible" title="Active partner managers" @close="close" inPlace>
    <template v-if="partnerManagerInfo && partnerManagerInfo.length">
      <p>
        Here you can see a list of partner managers that are currently looking for a
        solution to this order
      </p>
      <table class="table-active-pm-modal">
        <tr>
          <th class="table-header">NAME</th>
          <th class="table-header">ACTIVATION TIME</th>
        </tr>
        <tr v-for="pm in partnerManagerInfo" :key="pm.id">
          <td>{{ pm.fullName }}</td>
          <td>
            {{ `${humanizeIsoDateTimeWithDots(pm.activationDate)}` }}
          </td>
        </tr>
      </table>
    </template>
    <p v-else>No one is working on this order for now...</p>
  </Dialog>
</template>
<script>
import { fetchActivePartnerManagerForOffer } from '@/services/ordering-service'
import { humanizeIsoDateTimeWithDots } from '@sennder/plankton'
export default {
  props: {
    orderId: { type: Number, required: true }
  },
  data() {
    return {
      partnerManagerInfo: [],
      visible: false
    }
  },
  methods: {
    humanizeIsoDateTimeWithDots,
    async onFetchFilterOptions() {
      this.partnerManagerInfo = await fetchActivePartnerManagerForOffer(this.orderId)
    },
    close() {
      this.visible = false
    },
    show() {
      this.onFetchFilterOptions()
      this.visible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.table-active-pm-modal {
  width: 100%;

  tr {
    border-bottom: 1px solid $color-neutral-lightest;

    th {
      padding-top: 24px;
      padding-bottom: 12px;
    }

    td {
      padding: 12px 0;
      color: $color-neutral-main;
    }
  }
}
</style>
