<template>
  <HeaderActions data-test="order-header-actions">
    <dsf-button
      v-if="isFrontLinkEnabled && frontLink"
      dsf_level="secondary"
      :dsf_title="$t('single-view/front-discussion-link/title')"
      dsf_icon_left="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9Ii01NSAwIDM2NiAzNjYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiPgogICAgPGRlZnM+PGxpbmVhckdyYWRpZW50IHgxPSIxMi41MTg5NTM0JSIgeTE9Ijg1LjIxMjg2MTElIiB4Mj0iODguMjI4Mjk1OSUiIHkyPSIxMC4wMjI1NDk3JSIgaWQ9ImxpbmVhckdyYWRpZW50LTEiPjxzdG9wIHN0b3AtY29sb3I9IiNGRjAwNTciIHN0b3Atb3BhY2l0eT0iMC4xNiIgb2Zmc2V0PSIwJSI+PC9zdG9wPjxzdG9wIHN0b3AtY29sb3I9IiNGRjAwNTciIG9mZnNldD0iODYuMTM1NCUiPjwvc3RvcD48L2xpbmVhckdyYWRpZW50PjwvZGVmcz4KICAgIDxnPgogICAgICAgIDxwYXRoIGQ9Ik0wLDYwLjg1MzgwMDYgQzAsMjcuMjQ1MjYxIDI3LjI0NTMwNCwwIDYwLjg1NDIxMjEsMCBMMTE3LjAyNzAxOSwwIEwyNTUuOTk2NTQ5LDAgTDI1NS45OTY1NDksODYuNTk5OTc3NiBDMjU1Ljk5NjU0OSwxMDMuNDA0MTU1IDI0Mi4zNzQwOTYsMTE3LjAyNzIyMiAyMjUuNTY5OTE5LDExNy4wMjcyMjIgTDE0NS44MDgxMiwxMTcuMDI3MjIyIEMxMzAuMDAzMjk5LDExNy4yNzc4MjkgMTE3LjI0MjYxNSwxMzAuMDYwMDExIDExNy4wMjcwMTksMTQ1Ljg3MjgxNyBMMTE3LjAyNzAxOSwzMzUuMjgyNTIgQzExNy4wMjcwMTksMzUyLjA4NzMxMiAxMDMuNDA0NTY3LDM2NS43MDk3NjQgODYuNTk5Nzc0OSwzNjUuNzA5NzY0IEwwLDM2NS43MDk3NjQgTDAsMTE3LjAyNzIyMiBMMCw2MC44NTM4MDA2IFoiIGZpbGw9IiMwMDFCMzgiPjwvcGF0aD4KICAgICAgICA8Y2lyY2xlIGZpbGw9InVybCgjbGluZWFyR3JhZGllbnQtMSkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE0Ny4wMTMyNDQsIDE0Ny4wMTQ2NzUpIHJvdGF0ZSg5MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTQ3LjAxMzI0NCwgLTE0Ny4wMTQ2NzUpICIgY3g9IjE0Ny4wMTMyNDQiIGN5PSIxNDcuMDE0Njc1IiByPSI3OC45OTMzOTM4Ij48L2NpcmNsZT4KICAgICAgICA8Y2lyY2xlIGZpbGw9InVybCgjbGluZWFyR3JhZGllbnQtMSkiIG9wYWNpdHk9IjAuNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQ3LjAxMzI0NCwgMTQ3LjAxNDY3NSkgcm90YXRlKDkwLjAwMDAwMCkgdHJhbnNsYXRlKC0xNDcuMDEzMjQ0LCAtMTQ3LjAxNDY3NSkgIiBjeD0iMTQ3LjAxMzI0NCIgY3k9IjE0Ny4wMTQ2NzUiIHI9Ijc4Ljk5MzM5MzgiPjwvY2lyY2xlPgogICAgPC9nPgo8L3N2Zz4="
      dsf_square=""
      dsf_size="small"
      @click="openFrontConversation()"
    ></dsf-button>
    <Button
      data-test="copy-order-details"
      @click="shipmentId ? copyShipmentDetails() : copyOrderDetails(order, stops)"
      category="tertiary"
      v-tooltip.bottom="
        $t('ordering-share-header/order-header-action-btn-copy-details-tooltip')
      "
      inline
    >
      <i class="material-icons">assignment</i>
    </Button>

    <ReportIncidentButton
      v-if="order.state !== 'NEW'"
      :orderId="order.id"
      :warehouseIds="warehouseIdsOptions"
      :shipmentId="shipmentId"
      :facilityIds="facilityIdsOptions"
      data-test="report-incident-button"
      @submit="onIncidentReported"
    />

    <template v-if="isEnableClaimAccessorial && canSubmitClaim">
      <CreateClaimButton :orderId="order.id" />
    </template>
    <template v-else>
      <Button
        v-if="canSubmitClaim"
        class="header-button"
        @click="openSubmitClaimModal"
        category="tertiary"
        v-tooltip.bottom="
          $t('ordering-share-header/order-header-action-btn-clain-tooltip')
        "
        inline
        icon="fileClaim"
        data-test="file-claim file-claim__octopus-test"
      />
    </template>

    <Button
      data-test="comments-and-activity"
      @click="sidebarToggle"
      category="tertiary"
      v-tooltip.bottom="
        $t('ordering-share-header/order-header-action-btn-comments-and-activity-tooltip')
      "
      inline
      icon="comments"
    />
    <Button
      data-test="activate-pms-trigger"
      :disabled="isTogglingActivePM"
      :loading="isTogglingActivePM"
      v-if="currentUserIsPartnerManager && order.state == 'REGISTERED'"
      style="display: none"
      class="header-button"
      :class="{ 'active-pm': isActivePM }"
      @click="toggleActivePM"
      category="tertiary"
      v-tooltip.bottom="
        !isActivePM
          ? $t('ordering-share-header/order-header-action-btn-active-pm-tooltip-if')
          : $t('ordering-share-header/order-header-action-btn-active-pm-tooltip-else')
      "
      inline
      icon="raisedHand"
    />
    <loading-spinner
      v-if="
        isLoadShipmentDataInProgress ||
        isCreatingShipmentEditRequestInProgress ||
        isShipmentFeasibleLoading ||
        isFetchingShipmentEditRequestState
      "
    />
    <Button
      class="header-button"
      v-else-if="canEditOrderDetails"
      data-test="edit-order"
      @click="handleEditOrder"
      category="tertiary"
      v-tooltip.bottom="
        $t('ordering-share-header/order-header-action-btn-edit-order-tooltip')
      "
      inline
      icon="edit"
    />
    <RenderWithPermission :hasPermission="permissions.CAN_COPY_ORDER">
      <ShipmentCloneWarningModal
        :isOpen="isCloneWarningModalOpen"
        @on-proceed="handleCloneWarningModalProceed"
        @on-close="closeCloneWarningModal"
      />
      <Button
        v-if="!isPosteTheme"
        data-test="copy-order"
        @click="handleCopyShipmentCheck"
        category="tertiary"
        v-tooltip.bottom="copyTooltipMessage"
        inline
        icon="clone"
        :disabled="shouldDisableCloneButton"
      />
    </RenderWithPermission>
    <div>
      <div v-if="shipmentId">
        <loading-spinner
          v-if="
            shipmentEditRequestMainState === SHIPMENT_EDIT_REQUEST_MAIN_STATUS.PENDING
          "
        />
        <CancelOrderButton
          v-else-if="canCancelOrder"
          :orderId="order.id"
          data-test="shipment-cancellation-incident"
          :isShipment="true"
          @submit="cancelShipment"
        />
      </div>
      <div v-else>
        <CancelOrderButton
          v-if="canCancelOrder && order.state !== 'NEW'"
          :orderId="order.id"
          data-test="order-cancellation-incident"
          @submit="onCancellationReported"
        />
        <Button
          v-if="canCancelOrder && order.state === 'NEW'"
          data-test="cancel-new-order"
          @click="showCancelOrderModal"
          category="tertiary"
          v-tooltip.bottom="
            $t('ordering-share-header/order-header-action-btn-cancel-new-order-tooltip')
          "
          inline
          icon="xOctagon"
        />
      </div>
    </div>
    <Button
      v-if="canShowFinanceStage"
      data-test="goto-finance-view"
      class="stroke"
      @click="goToFinanceView"
      category="tertiary"
      v-tooltip.bottom="
        $t('ordering-share-header/order-header-action-btn-goto-finance-view-tooltip')
      "
      inline
      icon="euroSign"
    />
    <EditNewOrderModal
      ref="editNewOrderDialog"
      :confirmButtonText="
        $t('ordering-share-header/order-header-action-compo-edit-order-modal-btn-confirm')
      "
      :title="
        $t('ordering-share-header/order-header-action-compo-edit-order-modal-title')
      "
      data-test="edit-new-order-modal"
    >
      {{ $t('ordering-share-header/order-header-action-compo-edit-order-modal-text') }}
    </EditNewOrderModal>
    <NewWorldEditShipmentModal
      ref="newWorldEditShipmentModal"
      :orderStatus="order.state"
      :shipment="shipmentFormData"
      :confirmButtonText="'Save'"
      :title="$t('shipment/edit-modal-title')"
      :shipmentEditRequestMainState="shipmentEditRequestMainState"
      :isFetchingShipmentEditRequestState="isFetchingShipmentEditRequestState"
      :disableRescheduling="isShipmentReschedulingBlockedByChartering"
      data-test="edit-new-order-modal"
      @incident-reported="onIncidentReported"
      @submit="submitAndPollStatus"
    >
      {{ $t('shipment/edit-modal-details-text') }}
    </NewWorldEditShipmentModal>
    <EditOrderModal
      :orderId="order.id"
      ref="editOrderModal"
      data-test="edit-order-modal"
      @incident-reported="onIncidentReported"
    />
    <CancelOrderModal
      ref="cancelOrderModal"
      data-test="cancel-new-order-modal"
      @confirm="handleOrderCancellation"
    />
    <SubmitClaimModal
      ref="submitClaimModal"
      :orderId="order.id"
      :orderState="order.state"
      data-test="submit-claim-modal submit-claim-modal__octopus-test"
    />
  </HeaderActions>
</template>

<script>
import useStore from '@/compositions/useStore'
import moment from 'moment'
import { i18n } from '@/plugins/i18n'

import RenderWithPermission from '@/components/RenderWithPermission'
import * as permissions from '@/components/user-permissions'
import HeaderActions from './HeaderActions'
import EditNewOrderModal from './EditNewOrderModal'
import EditOrderModal from './EditOrderModal'
import NewWorldEditShipmentModal from './NewWorldEditShipmentModal'
import CancelOrderModal from './CancelOrderModal'
import {
  ReportIncidentButton,
  CancelOrderButton,
  useIncidentMappers
} from '@sennder/incident-management'
import { SubmitClaimModal, useOrderPaymentStatus } from '@sennder/finance-frontend'
import { canCancelOrder, canEditCustomerDetails } from './order-operations'
import { mapGetters } from 'vuex'
import orderingMappers from '@/modules/ordering/store/mappers'
import shipmentMappers from '@/modules/shipment/store/mappers'
import { trackEvent } from '@/analytics/amplitude'
import events from '@/modules/ordering/components/shared/header/events'
import {
  readPersistedState,
  writePersistedState,
  removePersistedState
} from '@/services/local-storage-service'
import {
  toggleSidebar,
  expandSidebar,
  refreshOrderIncidents
} from '@/modules/order-activity-sidebar'
import { hasHadState } from '@/modules/common/order-states'
import { toRef, computed, ref } from '@vue/composition-api'
import useMultipleLetters from '@/compositions/transfer/useMultipleLetters'
import { useShipment } from '@/modules/shipment/compositions/useShipment'
import { refreshOrderLogs } from '@/modules/order-activity-sidebar'
import { getParsedShipmentStopovers } from '@/modules/shipment/helpers/shipmentEditHelpers'
import ShipmentCloneWarningModal from '@/modules/shipment/components/ShipmentCloneWarningModal.vue'
import { transformKeysToCamelCase } from '@/services/utils/casing'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import useCurrentUser from '@/compositions/useCurrentUser'

import MessageToaster from '@/plugins/toaster/MessageToaster.vue'

import {
  MESSAGE_TOASTER_TIMEOUT,
  SUCCESS_TOAST_MESSAGE,
  IN_PROGRESS_TOAST_MESSAGE,
  TIMEOUT_TOAST_MESSAGE,
  POLL_API_INTERVAL,
  MAX_POLL_API_INTERVAL,
  SHIPMENT_CANCELLATION_TIMEOUT_TOAST_MESSAGE,
  SHIPMENT_CANCELLATION_IN_PROGRESS_TOAST_MESSAGE,
  SHIPMENT_CANCELLATION_SUCCESS_TOAST_MESSAGE,
  SHIPMENT_CANCELLATION_POLL_API_INTERVAL,
  SHIPMENT_EDIT_REQUEST_MAIN_STATUS,
  SHIPMENT_CREATION_MODE,
  OLD_SHIPMENT_CREATION_DATE,
  SHIPPER_PROFILE_CLOSED_LIVE_ACCOUNT_STATUS
} from '@/modules/shipment/constants'
import { getLogger } from '@/shell/datadog-logger'
import CreateClaimButton from '@/microfrontends/widgets/payments-widget/CreateClaimButton/CreateClaimButton.vue'
import { getShipmentDetailsForClipboard } from './shipment-details'
import { getOrderDetailsForClipboard } from './order-details'
import { isPosteTheme } from '@/controllers/environment-detection'

export default {
  components: {
    RenderWithPermission,
    HeaderActions,
    EditNewOrderModal,
    EditOrderModal,
    NewWorldEditShipmentModal,
    CancelOrderModal,
    ReportIncidentButton,
    CancelOrderButton,
    SubmitClaimModal,
    ShipmentCloneWarningModal,
    CreateClaimButton
  },
  props: {
    order: { type: Object, required: true },
    stops: { type: Array, required: true }
  },
  setup(props) {
    const store = useStore()
    const { user, loadCurrentUser } = useCurrentUser()
    const { addressIdsLettersMap, warehouseIdsOptions } = useMultipleLetters(
      toRef(props, 'stops')
    )
    const { mapWarehouseAddressIdsToFacilityIds } = useIncidentMappers()

    const { isActive, getJSONValue } = useFeatureFlag()

    const isEnableClaimAccessorial = isActive('ENABLE_CLAIM_ACCESSORIAL')
    const isNewV3OrderSidebarEnabled = isActive('ENABLE_NEW-V3-ORDER-SIDEBAR')
    const isNewV3OrderHeaderEnabled = isActive('ENABLE_NEW-V3-ORDER-HEADER')

    const {
      shipment,
      loadShipmentData,
      isLoadShipmentDataInProgress,
      customer,
      contact,
      createShipmentEditRequest,
      isCreatingShipmentEditRequestInProgress,
      getShipmentIdByOrderId,
      generateShipmentEditRequestPayload,
      isShipmentEditRequestSuccessful,
      shipmentEditRequestId,
      shipperProfileaccountStatus
    } = useShipment()

    const enableShippersForFrontConversation = getJSONValue(
      'ENABLE_ONE-VIEW-FRONT-CONVERSATION-SHIPPER-LIST'
    ).value

    const isFrontLinkEnabled = computed(() => {
      return enableShippersForFrontConversation?.shipper_list?.includes(
        shipment.value?.shipper.profile_id
      )
    })

    const facilityIdsOptions = computed(() => {
      if (shipment.value === null) {
        return null
      }

      const allFacilities = shipment.value.loads
        .flatMap(load => [
          ...load.load_requirements.loading_requirements,
          ...load.load_requirements.unloading_requirements
        ])
        .map(req => ({
          mothershipWarehouseAddressId: req.warehouse_address_id,
          facilityProfileId: req.facility_profile_id
        }))

      return mapWarehouseAddressIdsToFacilityIds(warehouseIdsOptions.value, allFacilities)
    })

    const submitClaimModal = ref(null)
    const newWorldEditShipmentModal = ref(null)
    const editOrderModal = ref(null)

    const { paymentInfo } = useOrderPaymentStatus(props.order.id)
    const shouldDisableCloneButton = computed(() => {
      return Boolean(
        shipperProfileaccountStatus.value &&
          shipperProfileaccountStatus.value !== SHIPPER_PROFILE_CLOSED_LIVE_ACCOUNT_STATUS
      )
    })
    const copyTooltipMessage = computed(() => {
      return shouldDisableCloneButton.value
        ? i18n.t('ordering-share-header/closed-live-copy-tooltip-message')
        : i18n.t('ordering-share-header/order-header-action-btn-copy-order-tooltip')
    })
    const isPaymentClosed = computed(
      () =>
        paymentInfo.value &&
        (paymentInfo.value.isCarrierCostClosed || paymentInfo.value.isCustomerPriceClosed)
    )

    const openNewWorldEditShipmentModal = async () => {
      newWorldEditShipmentModal.value.show(
        {
          customer: { id: customer.value?.id, name: customer.value?.name },
          contact: contact?.value,
          referenceNumber: shipment.value?.reference,
          trackingIdForShipper:
            shipment.value?.loads?.[0].load_requirements?.tracking_requirement
              ?.tracking_id_for_shipper,
          shipment
        },
        getParsedShipmentStopovers(shipment.value)
      )
      store.dispatch('shipment/resetShipmentPartialUpdate')
      store.commit('shipment/setShipmentEditRequestMainState', 'initial')
    }

    const openEditOrderModal = () => {
      editOrderModal.value.show(props.order, props.stops)
    }
    const copyShipmentDetails = async () => {
      let contentToAdd = getShipmentDetailsForClipboard(
        store.state.shipment?.shipmentV3?.shipment,
        transformKeysToCamelCase(store.state.shipment?.shipmentStops),
        user.value?.uuid
      )
      await navigator.clipboard.writeText(contentToAdd)
    }

    return {
      isPaymentClosed,
      addressIdsLettersMap,
      warehouseIdsOptions,
      facilityIdsOptions,
      submitClaimModal,
      newWorldEditShipmentModal,
      shipment,
      contact,
      isCreatingShipmentEditRequestInProgress,
      customer,
      openNewWorldEditShipmentModal,
      openEditOrderModal,
      editOrderModal,
      loadShipmentData,
      isLoadShipmentDataInProgress,
      createShipmentEditRequest,
      getShipmentIdByOrderId,
      generateShipmentEditRequestPayload,
      SHIPMENT_EDIT_REQUEST_MAIN_STATUS,
      user,
      loadCurrentUser,
      isShipmentEditRequestSuccessful,
      shipmentEditRequestId,
      shouldDisableCloneButton,
      copyTooltipMessage,
      isEnableClaimAccessorial,
      isNewV3OrderSidebarEnabled,
      isNewV3OrderHeaderEnabled,
      isFrontLinkEnabled,
      copyShipmentDetails,
      isPosteTheme
    }
  },
  data() {
    return {
      isTogglingActivePM: false,
      pollInterval: null,
      shipmentId: null,
      shipmentFormData: {},
      isCloneWarningModalOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'doesUserHavePermission',
      'currentUserIsAccountManager',
      'currentUserIsPartnerManager',
      'canUserAccessFinanceSection'
    ]),
    ...orderingMappers.mapGetters(['orderContainsShipment', 'getFrontLink']),
    ...orderingMappers.mapState([
      'isShipmentFeasible',
      'isShipmentFeasibleLoading',
      'isShipmentReschedulingBlockedByChartering',
      'frontLink'
    ]),
    ...shipmentMappers.mapState([
      'isFetchingShipmentEditRequestState',
      'shipmentEditRequestMainState',
      'hasShipmentEditAnyUpdates'
    ]),
    permissions: () => permissions,
    canSubmitClaim() {
      return this.canOpsSubmitClaim()
    },
    shouldWarnAboutCloning() {
      if (this.shipment?.created_at)
        return moment(this.shipment.created_at).isBefore(OLD_SHIPMENT_CREATION_DATE)
      return false
    },
    canCancelOrder() {
      const cancellationRules = [
        !this.order.isCancelled,
        canCancelOrder(this.order.state, this.currentUser.group_names),
        this.order.state === 'EXECUTED' ? !this.isPaymentClosed : true
      ]
      return cancellationRules.every(rule => rule === true)
    },
    isActivePM() {
      return (
        this.currentUserIsPartnerManager &&
        this.order.activePartnerManagersIds.includes(this.currentUser.id)
      )
    },
    canEditOrderDetails() {
      return canEditCustomerDetails(this.order.state, this.currentUser.group_names)
    },
    canShowFinanceStage() {
      return this.canUserAccessFinanceSection && this.$route.name !== 'financeOrderView'
    }
  },
  created() {
    this.shipmentFormData = this.shipment
  },
  async beforeMount() {
    await this.fetchLoadShipmentData()
  },
  async mounted() {
    await this.checkFeasibilityWorkflow()
    if (this.isShipmentFeasible) {
      await this.fetchShipmentEditRequestState()
      await this.updateStopoversData()
      await this.fetchLoadShipmentData()
    }

    const isCopied = readPersistedState(`copied-order-${this.order.id}`, false)
    if (isCopied && this.order.state === 'NEW') {
      this.editNewOrderDetails(isCopied)
      removePersistedState(`copied-order-${this.order.id}`)
    }
  },
  beforeDestroy() {
    this.stopPolling()
  },
  methods: {
    ...orderingMappers.mapActions([
      'updateOrderCustomerInformation',
      'deleteOrder',
      'cancelOrder',
      'copyOrder',
      'updateOrder',
      'checkFeasibilityWorkflow',
      'fetchCompleteOrder',
      'fetchOrder',
      'updateDefaultVisibleOrderState',
      'fetchFrontLink'
    ]),
    ...shipmentMappers.mapActions([
      'fetchShipmentV3',
      'fetchShipmentEditRequestState',
      'fetchShipmentEditRequest'
    ]),
    ...shipmentMappers.mapMutations(['setShipmentEditRequestMainState']),
    async fetchLoadShipmentData() {
      const shipmentId = await this.getShipmentIdByOrderId(this.order.id)

      if (shipmentId) {
        this.shipmentId = shipmentId
        await this.loadShipmentData(this.shipmentId, false)

        if (this.isFrontLinkEnabled) this.fetchFrontLink(shipmentId)
      }
    },
    closeCloneWarningModal() {
      this.isCloneWarningModalOpen = false
    },
    handleCloneWarningModalProceed() {
      this.closeCloneWarningModal()
      this.cloneOrderInfo()
    },
    handleCopyShipmentCheck() {
      if (this.shouldWarnAboutCloning) this.isCloneWarningModalOpen = true
      else this.cloneOrderInfo()
    },
    async updateDetailsAfterEdit() {
      await this.fetchShipmentEditRequest(this.shipmentEditRequestId)
      await this.updateStopoversData()
      await this.fetchLoadShipmentData()

      if (
        this.shipmentId &&
        (this.isNewV3OrderSidebarEnabled || this.isNewV3OrderHeaderEnabled)
      ) {
        await this.fetchShipmentV3(this.shipmentId)
      }
      if (
        !this.newWorldEditShipmentModal.visible &&
        this.isShipmentEditRequestSuccessful &&
        this.hasShipmentEditAnyUpdates
      )
        this.showUpdateProgressToastMessage(SUCCESS_TOAST_MESSAGE)
    },
    handleShipmentEditStatusPoll() {
      this.pollInterval = setInterval(async () => {
        if (this.shipmentEditRequestMainState === 'pending') {
          if (!this.newWorldEditShipmentModal.visible)
            this.showUpdateProgressToastMessage(IN_PROGRESS_TOAST_MESSAGE)
          await this.fetchShipmentEditRequestState()
        } else if (this.shipmentEditRequestMainState === 'updated') {
          if (this.shipmentEditRequestId) await this.updateDetailsAfterEdit()
          this.stopPolling()
        }
      }, POLL_API_INTERVAL)

      setTimeout(() => {
        this.showUpdateProgressToastMessage(TIMEOUT_TOAST_MESSAGE)
        this.stopPolling()
      }, MAX_POLL_API_INTERVAL)
    },
    stopPolling() {
      clearInterval(this.pollInterval)
    },
    goToFinanceView() {
      trackEvent(events.GO_TO_FINANCE, {})
      this.$router.push(`/finance/ordering/${this.order.idForStaff}`)
    },
    sidebarToggle() {
      toggleSidebar()
    },
    async onIncidentReported() {
      await refreshOrderIncidents()
      this.$emit('incident-reported')
      expandSidebar()
    },
    async onCancellationReported() {
      await Promise.all([this.onIncidentReported(), this.handleOrderCancellation()])
    },
    async handleSubmitAndPoll(
      setPendingStateFn,
      submitDataFn,
      successPollFn,
      errorPollFn
    ) {
      setPendingStateFn()
      await submitDataFn()

      if (this.isShipmentEditRequestSuccessful) {
        successPollFn()
      } else {
        errorPollFn()
      }
    },
    async submitAndPollStatus(formData) {
      await this.handleSubmitAndPoll(
        () =>
          this.setShipmentEditRequestMainState(SHIPMENT_EDIT_REQUEST_MAIN_STATUS.PENDING),
        () => this.submitShipmentData(formData),
        () => this.handleShipmentEditStatusPoll(),
        () =>
          this.setShipmentEditRequestMainState(SHIPMENT_EDIT_REQUEST_MAIN_STATUS.ERROR)
      )
    },
    async cancelShipment({ reason, comment = '' }) {
      const customerDetailsValue = {
        customer: { id: this.customer?.id, name: this.customer?.name },
        contact: this.contact,
        referenceNumber: this.shipment?.reference,
        trackingIdForShipper:
          this.shipment?.loads?.[0].load_requirements?.tracking_requirement
            ?.tracking_id_for_shipper
      }

      const payload = {
        shipment: customerDetailsValue,
        stops: getParsedShipmentStopovers(this.shipment),
        preferredVehicleType:
          this.shipment?.loads?.[0]?.load_requirements?.vehicle_requirement
            ?.preferred_vehicle_type,
        acceptableReplacementVehicleTypes:
          this.shipment?.loads?.[0]?.load_requirements?.vehicle_requirement
            .acceptable_replacement_vehicle_types,
        isShipmentCanceled: true,
        reason,
        comment,
        userId: this.user.id
      }

      await this.handleSubmitAndPoll(
        () =>
          this.setShipmentEditRequestMainState(SHIPMENT_EDIT_REQUEST_MAIN_STATUS.PENDING),
        () => this.submitShipmentData(payload),
        () => this.pollShipmentCancellationStatus(),
        () =>
          this.setShipmentEditRequestMainState(SHIPMENT_EDIT_REQUEST_MAIN_STATUS.ERROR)
      )
    },
    async submitShipmentData(formData) {
      const payload = this.generateShipmentEditRequestPayload(formData)
      await this.createShipmentEditRequest(payload, this.shipmentId)
    },
    async editOrderDetails() {
      trackEvent(events.EDIT_ORDER_MODAL_OPENED, {
        state: this.order.state
      })
      if (this.order.state === 'NEW') {
        this.editNewOrderDetails()
      } else if (this.orderContainsShipment) {
        if (!this.isShipmentFeasible) {
          this.openEditOrderModal()
        } else if (this.shipmentEditRequestMainState === 'updated') {
          await this.fetchLoadShipmentData()
          if (!this.isLoadShipmentDataInProgress) this.openNewWorldEditShipmentModal()
        } else {
          this.showUpdateProgressToastMessage(IN_PROGRESS_TOAST_MESSAGE)
        }
      } else {
        this.openEditOrderModal()
      }
    },
    showUpdateProgressToastMessage(message, timeout = MESSAGE_TOASTER_TIMEOUT) {
      this.$toast(MessageToaster, {
        message: message,
        timeout: timeout
      })
    },
    async handleEditOrder() {
      if (this.isShipmentFeasible) {
        this.shipmentFormData = this.shipment
        await this.fetchShipmentEditRequestState()
      }
      this.editOrderDetails()
    },
    async updateStopoversData() {
      await refreshOrderLogs()
      await this.fetchCompleteOrder(this.order.id)
    },
    async toggleActivePM() {
      if (!this.isTogglingActivePM) {
        this.isTogglingActivePM = true
        let index = this.order.activePartnerManagersIds.indexOf(this.currentUser.id)
        let activePartnerManagersIds = [...this.order.activePartnerManagersIds]
        if (index > -1) {
          activePartnerManagersIds.splice(index, 1)
        } else {
          activePartnerManagersIds.push(this.currentUser.id)
        }
        try {
          await this.updateOrder({
            order: {
              activePartnerManagersIds: activePartnerManagersIds
            }
          })
        } finally {
          this.isTogglingActivePM = false
        }
      }
    },
    async editNewOrderDetails(isCopied) {
      const isClonedOrder = isCopied !== undefined ? isCopied : false

      const data = await this.$refs.editNewOrderDialog.show({
        customer: { id: this.order.customerId, name: this.order.customerName },
        contact: this.order.customerContact,
        referenceNumber: this.order?.referenceNumber,
        trackingIdForShipper: this.order.trackingIdForShipper,
        customerCompanyAddress: this.order.customerCompanyAddress,
        showContractTypeField: isClonedOrder,
        showTemplateField: isClonedOrder,
        regularity: this.order.regularity,
        template: {
          lineCode: this.order.lineCode,
          originCompany: this.stops[0]?.customerCompany?.name,
          originCompanyAddress: this.stops[0]?.warehouseAddress?.fullAddress,
          destinationCompany: this.stops[this.stops.length - 1]?.customerCompany?.name,
          destinationCompanyAddress:
            this.stops[this.stops.length - 1]?.warehouseAddress?.fullAddress
        },
        isCopiedParam: isClonedOrder
      })

      if (data) {
        await this.updateOrderCustomerInformation({ orderId: this.order.id, ...data })

        if (isClonedOrder) {
          this.updateOrder({
            order: {
              ...this.order,
              ...data
            },
            isClonedOrder
          })
        }
      }
    },
    showCancelOrderModal() {
      trackEvent(events.CANCEL_ORDER_MODAL_OPENED, {})
      this.$refs.cancelOrderModal.show()
    },
    // TODO: should be refactored after the feature flag removal
    pollShipmentCancellationStatus() {
      this.showUpdateProgressToastMessage(
        SHIPMENT_CANCELLATION_IN_PROGRESS_TOAST_MESSAGE,
        SHIPMENT_CANCELLATION_POLL_API_INTERVAL
      )

      this.shipmetnCancellationpollInterval = setInterval(async () => {
        if (
          this.shipmentEditRequestMainState ===
            SHIPMENT_EDIT_REQUEST_MAIN_STATUS.INITIAL ||
          this.shipmentEditRequestMainState === SHIPMENT_EDIT_REQUEST_MAIN_STATUS.PENDING
        ) {
          this.showUpdateProgressToastMessage(
            SHIPMENT_CANCELLATION_IN_PROGRESS_TOAST_MESSAGE,
            SHIPMENT_CANCELLATION_POLL_API_INTERVAL
          )
          await this.fetchShipmentEditRequestState()
        } else if (
          this.shipmentEditRequestMainState === SHIPMENT_EDIT_REQUEST_MAIN_STATUS.UPDATED
        ) {
          this.showUpdateProgressToastMessage(SHIPMENT_CANCELLATION_SUCCESS_TOAST_MESSAGE)
          clearInterval(this.shipmetnCancellationpollInterval)
          await Promise.all([
            this.onIncidentReported(),
            this.fetchOrder(this.order.id)
          ]).then(() => {
            this.updateDefaultVisibleOrderState()
            refreshOrderLogs()
          })
        }
      }, SHIPMENT_CANCELLATION_POLL_API_INTERVAL)

      setTimeout(() => {
        this.showUpdateProgressToastMessage(SHIPMENT_CANCELLATION_TIMEOUT_TOAST_MESSAGE)
        clearInterval(this.shipmetnCancellationpollInterval)
      }, MAX_POLL_API_INTERVAL)
    },

    async cloneOrderInfo() {
      const {
        createShipmentDraft,
        copyShipmentFromOrder,
        copyShipmentFromShipment,
        constructShipmentPayload,
        copyShipmentManagersFromOrder
      } = useShipment()

      let shipmentDraftResponse
      const shipmentId = await this.getShipmentIdByOrderId(this.order.id)

      if (!shipmentId) {
        shipmentDraftResponse = await createShipmentDraft(
          {
            shipper_id: this.order.customerId,
            contact_id: this.order.customerContact.id,
            tracking_id_for_shipper:
              this.order.trackingIdForShipper === null
                ? undefined
                : this.order.trackingIdForShipper,
            reference: this.order.referenceNumber,
            id: crypto.randomUUID(),
            regularity: this.order.regularity
          },
          SHIPMENT_CREATION_MODE.CLONE
        )
      }

      const draftUUID = shipmentId ? crypto.randomUUID() : shipmentDraftResponse.id
      const payload = constructShipmentPayload(this.order, this.stops, draftUUID)
      const shipmentSourceLinkUrl = `/shipment/${draftUUID}?copySourceId=${shipmentId}`

      if (shipmentId) await copyShipmentFromShipment(draftUUID, shipmentId)
      else {
        await copyShipmentFromOrder(draftUUID, payload)
        await copyShipmentManagersFromOrder(this.order.id, draftUUID)
      }
      writePersistedState(`copied-shipment-draft-${draftUUID}`, true)

      /**
       * Log cloning result to DD
       */
      getLogger().info('order-cloned', {
        sourceOrderId: this.order.idForStaff,
        hasShipment: !!shipmentId,
        sourceShipmentId: shipmentId,
        newShipmentId: draftUUID
      })
      window.open(shipmentSourceLinkUrl)
    },
    async handleOrderCancellation() {
      if (this.order.state === 'NEW') {
        await this.deleteOrder()
        this.octopusRouter.navigateTo('/')
      } else {
        await this.cancelOrder()
      }
    },
    canOpsSubmitClaim() {
      return (
        (this.currentUserIsAccountManager &&
          hasHadState(this.order.state, 'REGISTERED')) ||
        (this.currentUserIsPartnerManager &&
          hasHadState(this.order.state, 'CARRIER_LOCKED'))
      )
    },

    openSubmitClaimModal() {
      this.$refs.submitClaimModal.open()
    },
    async copyOrderDetails(order, stops) {
      await this.loadCurrentUser()
      let contentToAdd = getOrderDetailsForClipboard(order, stops, this.user.uuid)
      await navigator.clipboard.writeText(contentToAdd)
    },
    async openFrontConversation() {
      window.open(this.getFrontLink, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.header-actions {
  .button::v-deep {
    color: $color-neutral-main;
    padding: 0;

    .button__icon {
      max-width: 24px;
      width: auto;
      height: auto;
    }
  }
}

.stroke::v-deep {
  svg {
    fill: none;
    stroke: currentColor;
  }
}

.active-pm::v-deep {
  svg {
    fill: none;
    stroke: $color-secondary-main;
  }
}

.material-icons {
  font-size: 18px;
  margin-top: 5px;
}
</style>
