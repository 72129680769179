<template>
  <fragment>
    <NextLoadsModal ref="dialog" />
    <Button
      :disabled="numberOfNextLoads === 0"
      category="secondary"
      @click="showNextLoads"
      class="next-loads action-button"
    >
      <NextLoadIcon class="next-load-icon" />
      {{ $t('ordering/dispatching-allocation-next-load-next-load') }}
      <span v-if="numberOfNextLoads > 0">({{ numberOfNextLoads }})</span>

      <slot />
    </Button>
  </fragment>
</template>

<script>
import { trackEvent } from '@/analytics/amplitude'

import NextLoadIcon from '@/modules/ordering/components/dispatching/carrier-allocations/NextLoadIcon.vue'
import NextLoadsModal from '@/modules/common/next-loads/NextLoadsModal.vue'

import { getNumberOfNextLoadsForOrder } from '@/services/next-loads-suggestions'

export default {
  components: {
    NextLoadIcon,
    NextLoadsModal
  },
  props: {
    orderId: { type: Number, required: true }
  },
  data() {
    return {
      idForStaff: '',
      numberOfNextLoads: 0
    }
  },
  async mounted() {
    try {
      const nextLoads = await getNumberOfNextLoadsForOrder(this.orderId)
      this.idForStaff = nextLoads.idForStaff
      this.numberOfNextLoads = nextLoads.count
    } catch (e) {
      /**
       * As for 10.04.2024 next-loads API is broken and probably will be removed entirely with the UI logic.
       * We want to suppress this error so it's not spamming our monitoring.
       * The button will always be disabled if the API is not working.
       */
      this.numberOfNextLoads = 0
    }
  },
  methods: {
    showNextLoads() {
      trackEvent('octopus/order-processing/next-loads/click', { order: this.idForStaff })
      this.$refs.dialog.showModal(this.orderId)
    }
  }
}
</script>

<style lang="scss" scoped>
.button::v-deep {
  margin-left: 10px;
}

.next-loads {
  display: inline-block;

  .next-load-icon {
    filter: grayscale(1) brightness(2);
    margin-top: -2px;
    margin-right: 10px;
  }

  &:disabled {
    .next-load-icon {
      filter: grayscale(1) brightness(1);
    }
  }
}
</style>
