import { computed, ref } from '@vue/composition-api'

import useStore, { Store } from '@/compositions/useStore'
import { useDataPolling } from '@/compositions'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import {
  ASSIGNMENT_TYPES,
  ASSIGNMENTS_FEATURE_FLAGS,
  OFFER_LIST_REFRESH_RATE
} from '@/modules/assignments/constants'
import { InternalAssignment, Order, OrderStop } from '../models'
import { useCharteringOffices } from './useCharteringOffices'

export interface AssignmentsProps {
  order: Order
  orderStops: OrderStop[]
}

export const useAssignments = (props: AssignmentsProps) => {
  const showView = ref(false)
  const errorWhileFetchingAssignments = ref(false)
  const store = useStore() as Store
  const { isActive } = useFeatureFlag()

  useCharteringOffices()

  const refetch = async () => {
    errorWhileFetchingAssignments.value = false
    const results = await Promise.allSettled([
      store.dispatch('assignments/fetchAssignments', {
        fetchOldAssignments: isActive(
          ASSIGNMENTS_FEATURE_FLAGS.ENABLE_MOTHERSHIP_ASSIGNMENTS
        ).value
      })
    ])

    if (results[0].status !== 'fulfilled') {
      errorWhileFetchingAssignments.value = true
    }

    await store.dispatch('assignments/fetchCarriersKpi', {
      orderId: props.order.id,
      customerId: props.order.customerId,
      orderStops: props.orderStops
    })

    showView.value = true
  }

  useDataPolling(refetch, OFFER_LIST_REFRESH_RATE)

  const assignments = computed(() => store.getters['assignments/assignments'])
  const pendingAssignment = computed<InternalAssignment>(
    () => store.getters['assignments/pendingAssignment']
  )

  const carrierKpi = computed(() => store.getters['assignments/carrierKpi'])

  return {
    showView,
    errorWhileFetchingAssignments,
    assignments,
    pendingAssignment,

    refetch,
    carrierKpi,

    assignmentTypes: ASSIGNMENT_TYPES
  }
}
