<template>
  <DropdownField
    :options="contacts"
    :placeholder="placeholder"
    :title="titleString"
    :validators="fieldValidators"
    v-bind="$attrs"
    searchable
    optionLabel="name"
    optionKey="id"
    class="customer-contact-field"
    data-test="contact-person-dropdown"
    @select="$emit('select', $event)"
  />
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { fetchCarrierContactsToBeDepreciated } from '@/services/carrier-service'
import { carrierProfileService } from '@/modules/assignments/services'

export default {
  model: { prop: 'value', event: 'select' },
  props: {
    carrierId: { type: [Number, String], required: false, default: null },
    placeholder: { type: String, default: 'Select contact' },
    title: { type: String, default: 'Contact person' },
    validators: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },
    inlineError: { type: Boolean, default: false },
    fetchFromNewEndpoint: { type: Boolean, default: false }
  },
  data() {
    return {
      contacts: []
    }
  },
  computed: {
    titleString() {
      return this.required ? this.title + '*' : this.title
    }
  },
  watch: {
    async carrierId(newCarrierId, oldCarrierId) {
      if (newCarrierId && oldCarrierId !== newCarrierId) {
        this.$emit('input', null)
        if (this.fetchFromNewEndpoint) {
          this.contacts = await carrierProfileService.getCarrierContacts(newCarrierId)
        } else {
          this.contacts = await fetchCarrierContactsToBeDepreciated(newCarrierId)
        }
        this.$emit('onCarrierOptionsFetched', this.contacts)
      } else if (newCarrierId === null) {
        this.contacts = []
        this.$emit('input', null)
      }
    }
  },
  created() {
    if (this.required) {
      this.fieldValidators = [
        ...this.validators,
        {
          isInvalid: isEmpty,
          errorMessage: 'Carrier contact cannot be empty',
          inline: this.inlineError
        }
      ]
    } else {
      this.fieldValidators = [...this.validators]
    }
  },
  async mounted() {
    if (this.carrierId !== null) {
      if (this.fetchFromNewEndpoint) {
        this.contacts = await carrierProfileService.getCarrierContacts(this.carrierId)
      } else {
        this.contacts = await fetchCarrierContactsToBeDepreciated(this.carrierId)
      }
      this.$emit('onCarrierOptionsFetched', this.contacts)
    }
  }
}
</script>
