



















































import { computed, defineComponent, ref, onMounted } from '@vue/composition-api'
import useI18n from '@/compositions/useI18n'
import useStore, { Store } from '@/compositions/useStore'
import useFeatureFlag from '@/compositions/useFeatureFlag'

import { ASSIGNMENTS_FEATURE_FLAGS } from '../../constants'

import ListCell from './ListCell.vue'

export default defineComponent({
  components: { ListCell },
  props: { qualityData: { type: Object, default: null } },
  setup(props) {
    const trackingRate = ref(null)
    const fetching = ref(true)
    const store = useStore() as Store
    const { t } = useI18n()
    const { isActive } = useFeatureFlag()

    const isTrackingRateEnabled = computed(
      () => isActive(ASSIGNMENTS_FEATURE_FLAGS.ENABLE_ASSIGNMENTS_TRACKING_RATE).value
    )

    const formattedOnTimePickupPercentage = computed(() => {
      if (props.qualityData.onTimePickupPercentage) {
        return `${Math.round(props.qualityData.onTimePickupPercentage)}`
      }
      return t('shared/N-A')
    })
    const formattedOnTimeDeliveryPercentage = computed(() => {
      if (props.qualityData.onTimeDeliveryPercentage) {
        return `${Math.round(props.qualityData.onTimeDeliveryPercentage)}`
      }
      return t('shared/N-A')
    })
    const trackingRatePercentage = computed(() => {
      if (trackingRate.value || trackingRate.value === 0) {
        return `${Math.round(trackingRate.value * 100)}`
      }
      return t('shared/N-A')
    })

    onMounted(async () => {
      if (props.qualityData && isTrackingRateEnabled.value) {
        const { carrierId } = props.qualityData
        trackingRate.value = await store.dispatch(
          'assignments/fetchCarrierTrackingRate',
          {
            carrierId,
            fetchAgain: false
          }
        )
      }

      fetching.value = false
    })

    return {
      trackingRate,
      fetching,

      formattedOnTimePickupPercentage,
      formattedOnTimeDeliveryPercentage,
      trackingRatePercentage,

      isTrackingRateEnabled
    }
  }
})
