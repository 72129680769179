/**
 * 🚨🚨🚨🚨🚨 IMPORTANT 🚨🚨🚨🚨🚨
 * 🚨🚨 WHILE ADDING A NEW FEATURE FLAG 🚨🚨
 * 🚨🚨 PLEASE ADD A CORRESPONDING ENTRY IN THE src/microfrontends/pages/single-view/SingleViewPage.vue FILE TOO 🚨🚨
 * 🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨
 */
export const BIDDING_FEATURE_FLAGS = {
  SHOW_MANUAL_SUGGESTED_BASE_COST: 'SHOW_MANUAL-SUGGESTED-BASE-COST',
  IS_SUBMIT_OFFER_ENABLED_FOR_DOMESTIC_CARRIERS_PAID_IN_LOCAL_CURRENCY:
    'ENABLE_SUBMIT-OFFER-FOR-DOMESTIC-CARRIERS-PAID-IN-LOCAL-CURRENCY',
  MAKE_COUNTEROFFER_MANDATORY: 'MAKE_COUNTEROFFER-MANDATORY',
  ENABLE_ORDER_DETAILS_TARGET_DEVIATION: 'ENABLE_ORDER-DETAILS-TARGET-DEVIATION'
}
