var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.order && _vm.order.id)?_c('div',{key:_vm.componentKey,class:{
    'ordering-root': true,
    'ordering-root--full-screen': _vm.enableFullScreen
  }},[_c('BiddingMicrofrontendLoader',{attrs:{"orderId":_vm.order.id}}),(_vm.isNewV3OrderHeaderEnabled && _vm.isShipmentLinkedToOrder)?_c('OrderInformationHeaderV3',{attrs:{"order":_vm.order,"stops":_vm.orderStops,"spotCosting":_vm.spotCosting,"subContractingPolicy":_vm.subContractingPolicy,"routeSummary":_vm.routeSummary,"referenceNumber":_vm.computedOrderFromShipmentV3.referenceNumber,"trackingIdForShipper":_vm.computedOrderFromShipmentV3.trackingIdForShipper,"regularity":_vm.computedOrderFromShipmentV3.regularity,"shipperSensitivity":_vm.shipperSensitivity,"shipmentId":_vm.shipmentId}}):_c('OrderInformationHeader',{attrs:{"order":_vm.order,"stops":_vm.orderStops,"spotCosting":_vm.spotCosting,"subContractingPolicy":_vm.subContractingPolicy,"shipperSensitivity":_vm.shipperSensitivity,"shipmentId":_vm.shipmentId}}),_c('div',{staticClass:"content-wrapper"},[(
        _vm.isNewV3OrderSidebarEnabled &&
        _vm.isShipmentLinkedToOrder &&
        _vm.visibleState !== 'NEW' &&
        _vm.visibleState !== 'PLANNING'
      )?_c('OrderSidebarV3',{key:"1",staticClass:"sidebar-content",attrs:{"loading":!_vm.isShipmentStopsLoaded,"order":_vm.computedOrderFromShipmentV3,"stops":_vm.shipmentStops,"totalDistance":_vm.totalDistance}}):(
        _vm.visibleState !== 'NEW' &&
        _vm.visibleState !== 'PLANNING' &&
        _vm.orderStops &&
        _vm.orderStops.length
      )?_c('OrderSidebar',{key:"1",staticClass:"sidebar-content",attrs:{"order":_vm.order,"stops":_vm.orderStops}}):_vm._e(),_c('OrderStageContent',{attrs:{"order":_vm.order,"stops":_vm.orderStops,"accountManager":_vm.accountManager,"juniorAccountManager":_vm.juniorAccountManager,"partnerManager":_vm.partnerManager,"spotBidder":_vm.spotBidder},on:{"update-order-regularity":_vm.onUpdateOrderRegularity}})],1),_c('OrderActivitySidebar',{attrs:{"orderId":_vm.order.id,"currentUserId":_vm.currentUserId,"stops":_vm.orderStops}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }