var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListCellStatus',_vm._b({attrs:{"data-test":"cell-status"}},'ListCellStatus',_vm.configuration,false),[_c('fragment',[(_vm.configuration.canWithdraw && !_vm.lockAssignmentActions)?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t(
          'ordering/dispatching-list-cells-account-manager-withdraw-assignment'
        ),
        delay: { show: 500 }
      }),expression:"{\n        content: $t(\n          'ordering/dispatching-list-cells-account-manager-withdraw-assignment'\n        ),\n        delay: { show: 500 }\n      }"}],attrs:{"inline":"","materialIcon":"delete","category":"tertiary","data-test":"withdraw-allocation"},on:{"click":function($event){return _vm.$emit('withdraw')}}}):_vm._e(),(_vm.configuration.canAssign && !_vm.lockAssignmentActions)?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.disableAssignOption
          ? _vm.$t(
              'ordering/dispatching-list-cells-account-manager-mothership-assignment-not-supported'
            )
          : _vm.$t('ordering/dispatching-list-cells-account-manager-assign-offer'),
        delay: { show: 500 }
      }),expression:"{\n        content: disableAssignOption\n          ? $t(\n              'ordering/dispatching-list-cells-account-manager-mothership-assignment-not-supported'\n            )\n          : $t('ordering/dispatching-list-cells-account-manager-assign-offer'),\n        delay: { show: 500 }\n      }"}],attrs:{"inline":"","disabled":_vm.disableAssignOption,"materialIcon":"check","category":"primary","data-test":"assign-offer"},on:{"click":function($event){return _vm.$emit('assign')}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }