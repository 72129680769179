<template>
  <ListCell class="root">
    <div data-test="offer-status">
      <Status :state="visualStatus" :text="text" class="status" />
      <p v-if="description" class="description paragraph" data-test="status-label">
        {{ description }}
      </p>
    </div>
    <div class="actions">
      <slot></slot>
    </div>
  </ListCell>
</template>
<script>
import ListCell from './ListCell'

export default {
  components: { ListCell },
  props: {
    text: { type: String, required: true },
    description: { type: String, default: null },
    visualStatus: { type: String, required: true }
  }
}
</script>
<style lang="scss" scoped>
.root {
  display: flex;

  &::v-deep span > .button {
    margin-left: 8px;
  }

  .description {
    padding-left: 18px;
    margin-bottom: 0;
  }

  .actions {
    flex-grow: 1;
    text-align: right;
    min-width: 72px;
  }

  &::v-deep .status {
    color: $color-neutral-main;
  }
}
</style>
