// FIXME: [refactoring] change this file to Typescript
import uniq from 'lodash/uniq'

import useFeatureFlag from '@/compositions/useFeatureFlag'

export const orderStops = state => {
  return state.orderStops
}

export const visibleTabs = (state, getters, rootState, rootGetters) => {
  return state.navItems.filter(item => {
    if (item.states.includes('OPERATIONS_COMPLETED'))
      return rootGetters.currentUserIsInFinanceGroup
    else if (item.states.includes('DISPATCHED') || item.states.includes('EXECUTED'))
      return (
        rootGetters.currentUserIsAccountManager ||
        rootGetters.currentUserIsPartnerManager ||
        rootGetters.currentUserIsCallCenter
      )
    // Planning tab is only for the new ordering flow and now in development
    // will be displayed only if shipment is presented
    else if (item.states.includes('PLANNING')) {
      return canSeeManualSchedulingTab(state, getters, rootState)
    } else
      return (
        rootGetters.currentUserIsAccountManager || rootGetters.currentUserIsPartnerManager
      )
  })
}

export const canSeeManualSchedulingTab = (state, getters, rootState) => {
  const shipmentId = getShipmentID(state)
  if (!shipmentId) return false
  const { shipment } = rootState
  const { getJSONValue } = useFeatureFlag()

  const permittedList = getJSONValue('ENABLE_FACILITY_MANUAL_SCHEDULING_NW').value
  const permittedReadList = getJSONValue(
    'ENABLE_FACILITY_SCHEDULING_TAB_READ_ONLY_NW'
  ).value

  return (
    permittedList.onboarded_shippers_id?.includes(
      shipment?.shipmentV3?.shipment?.shipper?.profile_id
    ) ||
    permittedReadList.onboarded_shippers_id?.includes(
      shipment?.shipmentV3?.shipment?.shipper?.profile_id
    )
  )
}

export const orderCarriersId = (state, getters, rootState, rootGetters) => {
  const assignmentscarrierId = state.assignments.map(
    assignment => assignment.carrier.mothershipId || assignment.carrier.id
  )
  const offers = rootGetters.currentUserIsAccountManager
    ? state.accountManagerOfferList.map(offer => offer.carrierId)
    : state.partnerManagerOfferList.map(offer => offer.carrierId)
  return uniq(assignmentscarrierId.concat(offers))
}

export const isJustOneTabVisible = (state, getters) => getters.visibleTabs.length <= 1

// TODO: [refactor] logic to have a better naming, something like: hasAssignmentsInProgress
export const pendingAssignment = state =>
  state.assignments.find(
    assignment =>
      assignment.state === 'PENDING' ||
      assignment.state === 'AWAITING_MATCHING_CONFIRMATION'
  )

export const hasPendingAssignment = (state, getters) => Boolean(getters.pendingAssignment)

export const acceptedAssignment = state =>
  state.accountManagerOfferList.find(offer => offer.state === 'ACCEPTED')

export const hasAcceptedAssignment = (state, getters) =>
  Boolean(getters.acceptedAssignment)

export const carrierKpi = state => carrierId => {
  return state.carriersKpi?.length
    ? state.carriersKpi.find(t => t.carrierId === carrierId)
    : null
}

export const orderContainsShipment = state => {
  return state.shipmentData?.length > 0
}

export const getShipmentID = state => {
  return state.shipmentData?.[0]?.id
}

export const getOrderId = state => {
  return state.order.id
}

export const getTransportOfferId = state => {
  return state.transportOfferId
}

export const getOrderState = state => {
  return state.order.state
}

export const hasExecutionPlan = state => {
  return !!state.executionPlanData
}

export const getExecutionPlan = state => {
  return state.executionPlanData
}

export const getTotalNetPrice = state => {
  return state.order.totalNetPrice
}

export const isShipperPilotForSimpliedCarrierAssignment = state =>
  !!state.order.workflowManaged

export const containsShipmentsAndIsShipperPilot = (_, getters) => {
  return (
    getters.isShipperPilotForSimpliedCarrierAssignment && getters.orderContainsShipment
  )
}

export const getFrontLink = state => {
  return state.frontLink
}
