<template>
  <Dialog
    v-bind="$attrs"
    :disabled="inProgressRequest"
    :show="showDialog"
    :title="$t('ordering/dispatching-assignment-cancel-carrier-assignment-dialog-title')"
    :confirmButtonText="$t('global/yes')"
    :cancelButtonText="$t('global/cancel')"
    inPlace
    class="cancel-carrier-assignment-modal"
    @close="handleCancelEvent"
    @cancel="handleCancelEvent"
    @confirm="handleConfirmEvent"
    v-on="$listeners"
  >
    <p class="modal-text">
      <i18n
        path="ordering/dispatching-assignment-cancel-carrier-assignment-dialog-content"
      >
        <span class="modal-bold">
          {{ $t('ordering/ordering-dispatch-form-shared-order-number', [idForStaff]) }}
        </span>
        <span class="modal-bold">{{ carrierCompany && carrierCompany.name }}</span>
      </i18n>
    </p>
  </Dialog>
</template>

<script>
import {
  cancelCarrierAssignmentToBeDepreciated,
  cancelCarrierAssignment
} from '@/services/order-assignment-service'
import { computed } from '@vue/composition-api'
import useStore from '@/compositions/useStore'

export default {
  setup() {
    const store = useStore()
    const shouldUseNewEndpoints = computed(
      () =>
        // TODO: [refactoring] decouple order state
        store.getters['ordering/containsShipmentsAndIsShipperPilot']
    )
    return {
      shouldUseNewEndpoints,
      showDialog: false,
      carrierCompany: null,
      idForStaff: null,
      assignmentId: null,
      inProgressRequest: false
    }
  },
  methods: {
    show({ carrierCompany, assignmentId, idForStaff }) {
      return new Promise(resolve => {
        this.resolve = resolve
        this.carrierCompany = carrierCompany
        this.assignmentId = assignmentId
        this.idForStaff = idForStaff
        this.showDialog = true
      })
    },
    async handleConfirmEvent() {
      try {
        this.inProgressRequest = true
        this.shouldUseNewEndpoints
          ? await cancelCarrierAssignment(this.assignmentId)
          : await cancelCarrierAssignmentToBeDepreciated(this.assignmentId)
        this.resolve && this.resolve('OK')
        this.resolve = null
        this.showDialog = false
      } finally {
        this.inProgressRequest = false
      }
    },
    handleCancelEvent() {
      this.resolve && this.resolve('CANCEL')
      this.showDialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-text {
  color: $color-neutral-main;
  text-align: justify;
}

.modal-bold {
  color: $color-neutral-darkest;
}
</style>
