import { useTenants } from '@/compositions/useTenants'
import { Country, Currency } from '@/modules/common'

interface Subsidiary {
  id: number
  name: string
  paymentCurrency: Currency
  country: Country
  domesticOnly: boolean
}

const SUBSIDIARIES: Subsidiary[] = [
  {
    id: 1,
    name: 'sennder Technologies GmbH',
    paymentCurrency: Currency.EUR,
    country: Country.Germany,
    domesticOnly: false
  },
  {
    id: 2,
    name: 'sennder Polska Sp. z o.o',
    paymentCurrency: Currency.PLN,
    country: Country.Poland,
    domesticOnly: true
  },
  {
    id: 3,
    name: 'sennder Italia S.r.l',
    paymentCurrency: Currency.EUR,
    country: Country.Italy,
    domesticOnly: false
  },
  {
    id: 4,
    name: 'sennder Iberia, S.L.U',
    paymentCurrency: Currency.EUR,
    country: Country.Spain,
    domesticOnly: true
  }
]

export const useSubsidiaries = () => {
  const { isPosteTenant } = useTenants()

  const subsidiaries = SUBSIDIARIES
  const subsidiariesHash = subsidiaries.reduce(
    (previous, current) => ({ ...previous, [current.id]: current }),
    {}
  )

  const getDefaultSubsidiary = (): Subsidiary =>
    isPosteTenant() ? subsidiaries[2] : subsidiaries[0]

  const getSubsidiaries = (subsidiaryIds: number[]): Subsidiary[] =>
    subsidiaryIds.map(x => subsidiariesHash[x])

  const getMainSubsidiary = (subsidiaryIds: number[]): Subsidiary =>
    getSubsidiaries(subsidiaryIds)[0]

  return {
    subsidiaries,
    getSubsidiaries,
    getDefaultSubsidiary,
    getMainSubsidiary
  }
}
