import { runJsonGet } from '@/models/backend-client'
import { OPERATIONS_BACKEND_URL, USER_MANAGEMENT_API_URL } from '@/config'
import { transformKeysToCamelCase } from '@/services/utils/casing'
import { fullName } from '@/modules/common/filters/person-filters'

const REQUIRED_ROLE_FOR_OPERATORS = 'Staff'

export const fetchUsersByEmailPattern = async pattern => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/accounts/queries/persons/find/staff/${encodeURIComponent(
      pattern
    )}`
  )
  return response.data.persons
}

export const fetchPersonsByIds = async personIds => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/accounts/queries/get-person-list`,
    { id: personIds }
  )
  return response.data
}

export const fetchStaffUsers = async () => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/user-management/queries/get-all-staff-users`
  )
  return response.data && response.data.map(user => transformKeysToCamelCase(user))
}

export const fetchUserByIdUserManagement = async (id, camelCase = false) => {
  const response = await runJsonGet(
    `${USER_MANAGEMENT_API_URL}/api/users/mothership/${id}`,
    {},
    {},
    process.env.VUE_APP_AUTH0_USER_MANAGEMENT_AUDIENCE,
    ['users:read-any']
  )
  if (camelCase) return transformKeysToCamelCase(response.data)
  else return response.data
}

export const fetchUserByUuid = async id => {
  const response = await runJsonGet(
    `${USER_MANAGEMENT_API_URL}/api/users/${id}`,
    {},
    {},
    process.env.VUE_APP_AUTH0_USER_MANAGEMENT_AUDIENCE,
    ['users:read-any']
  )
  return transformKeysToCamelCase(response.data)
}

export const fetchUserRoles = async id => {
  const response = await runJsonGet(
    `${USER_MANAGEMENT_API_URL}/api/users/${id}/roles`,
    {},
    {},
    process.env.VUE_APP_AUTH0_USER_MANAGEMENT_AUDIENCE,
    ['users:read-any']
  )
  return transformKeysToCamelCase(response.data)
}

export const fetchOperatorByName = async pattern => {
  const response = await runJsonGet(
    `${USER_MANAGEMENT_API_URL}/api/users/filter`,
    {
      value: pattern,
      role: REQUIRED_ROLE_FOR_OPERATORS
    },
    {},
    process.env.VUE_APP_AUTH0_USER_MANAGEMENT_AUDIENCE,
    ['users:read-any']
  )
  return (
    response.data &&
    response.data.map(user => {
      if (user.mothership_id) {
        user.uuid = user.id
        user.id = user.mothership_id
      }
      return transformKeysToCamelCase(user)
    })
  )
}

export const fetchAccountManagerBynamePattern = async pattern => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-account-managers`,
    { name_pattern: pattern }
  )
  return transformKeysToCamelCase(response.data)
}

export const fetchPartnerManagerByNamePattern = async pattern => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/ordering/queries/get-partner-managers`,
    { name_pattern: pattern }
  )
  return transformKeysToCamelCase(response.data)
}

// TODO: Remove camelCase patameter after refactoring old code using snake_case.
export const fetchUserById = async (id, camelCase = false) => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/accounts/queries/person/${id}`
  )
  response.data.email = response.data.username
  response.data.name = fullName(transformKeysToCamelCase(response.data))

  if (camelCase) return transformKeysToCamelCase(response.data)
  else return response.data
}

// TODO: Remove camelCase patameter after refactoring old code using snake_case.
export const fetchDriversByEmailPattern = async (pattern, camelCase = false) => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/accounts/queries/get-drivers-by-email/${encodeURIComponent(
      pattern
    )}`
  )
  if (camelCase) return transformKeysToCamelCase(response.data)
  else return response.data
}

export const fetchDriverById = async id => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/accounts/queries/get-driver-by-id/${encodeURIComponent(
      id
    )}`
  )
  return response.data
}

export const fetchUserLevel = async () => {
  const response = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/user-management/queries/user-level`
  )
  return response.data
}

export const fetchUserDataById = async userId => {
  const result = await runJsonGet(
    `${OPERATIONS_BACKEND_URL}/user-management/queries/user?id=${userId}`
  )
  return transformKeysToCamelCase(result.data)
}
