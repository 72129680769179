<template>
  <nav class="navigation-menu">
    <router-link class="link-to-homepage" to="/" exact>
      <img src="../../../assets/images/logo_gray.svg" />
    </router-link>

    <section v-if="!currentUserIsCallCenter" class="links-top">
      <router-link
        v-tooltip.right="'Search board'"
        :to="esSearchBoardIsAllowed ? '/dashboard/es-overview' : '/dashboard/overview'"
        class="navigation-link"
        data-test="button-overview"
      >
        <i class="material-icons">search</i>
      </router-link>

      <router-link
        v-tooltip.right="$t('layout/nav-bar-finance-board-tooltip')"
        v-if="currentUserIsInFinanceGroup"
        class="navigation-link"
        to="/dashboard/finance"
        data-test="finance-board-link"
      >
        <i class="material-icons">euro_symbol</i>
      </router-link>

      <RenderWithPermission
        class="navigation-link"
        :hasAnyOf="[
          permissions.CAN_VIEW_CONTRACT_MANAGEMENT,
          permissions.CAN_VIEW_CONTRACT_MANAGEMENT_AUTH0
        ]"
      >
        <router-link
          v-tooltip.right="'Contract Management board'"
          class="navigation-link"
          to="/contract-management"
          data-test="contract-management-link"
        >
          <i class="material-icons">description</i>
        </router-link>
      </RenderWithPermission>
    </section>

    <section class="links-bottom">
      <ContextMenu
        autoHide
        trigger="click"
        placement="top-start"
        @show="showUserMenu"
        @hide="hideUserMenu"
        class="navigation-link"
        data-test="user-menu-popover"
      >
        <button
          :class="{ 'navigation-link--active': isUserMenuActive }"
          class="navigation-link"
          data-test="user-menu-toggle-button"
        >
          <i class="material-icons">person</i>
        </button>
        <template #menu-items>
          <ContextMenuItem
            v-if="
              hasPermission(CAN_VIEW_COST_CENTER_AUTH0) ||
              (COST_CENTRE_ENABLED && currentUserIsFinance)
            "
            icon="building"
            :text="$t('layout/nav-bar-cost-centre-label')"
            class="cost-centre-menu-item"
            data-test="cost-center-link"
            @click="goToCostCenter"
          />
          <LanguageMenu v-if="isMultiLanguage" />
          <ContextMenuItem
            icon="lock"
            text="Logout"
            data-test="logout-button"
            @click="handleLogoutButtonClick"
          />
        </template>
      </ContextMenu>
    </section>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import User from '@/models/user'
import RenderWithPermission from '@/components/RenderWithPermission'
import * as permissions from '@/components/user-permissions'
import useCurrentUser from '@/compositions/useCurrentUser'
import { AVAILABLE_TRANSLATIONS, COST_CENTRE_ENABLED } from '@/config'
import { CAN_VIEW_COST_CENTER_AUTH0 } from '@/components/user-permissions'
import { resetAnalyticsSession } from '@/analytics/amplitude'

import LanguageMenu from './LanguageMenu.vue'

export default {
  components: {
    RenderWithPermission,
    LanguageMenu
  },
  setup() {
    const { currentUserIsClaimAccessorialManager, currentUserIsFinance, hasPermission } =
      useCurrentUser()
    const { identifyAnonymous } = useFeatureFlag()

    return {
      permissions,
      currentUserIsClaimAccessorialManager,
      currentUserIsFinance,
      COST_CENTRE_ENABLED,
      hasPermission,
      CAN_VIEW_COST_CENTER_AUTH0,
      identifyAnonymous
    }
  },
  data() {
    return {
      isUserMenuActive: false,
      newTransportRequestsExist: false
    }
  },
  computed: {
    isMultiLanguage() {
      return AVAILABLE_TRANSLATIONS.length > 1
    },
    ...mapGetters([
      'currentUserIsInFinanceGroup',
      'currentUserIsCallCenter',
      'currentUserIsStaff',
      'currentUserIsAccountManager',
      'currentUserIsPartnerManager',
      'currentUserIsDirectDispatcher',
      'currentUserIsInEngineering',
      'currentUserIsProductOwner',
      'currentUserIsPartnerManagementAdmin',
      'currentUserIsPmaReadOnlyUser'
    ]),
    hasNewTransportRequestNotifications() {
      return this.newTransportRequestsExist && this.currentUserIsAccountManager
    },
    singleViewBoardIsEnabled() {
      const { isActive } = useFeatureFlag()
      return isActive('ENABLE_ONE-VIEW-BOARD').value
    },
    sennTeamsPageIsEnabled() {
      const { isActive } = useFeatureFlag()
      return isActive('ENABLE_SENNTEAMS_PAGE').value
    },
    esSearchBoardIsAllowed() {
      const { isActive } = useFeatureFlag()
      return isActive('ENABLE_NEW_GLOBAL_SEARCH_PAGE')?.value
    },
    shouldBeSpotterActive() {
      const { isActive } = useFeatureFlag()

      return (
        isActive('ENABLE_SPOTTER_PAGE').value &&
        (this.currentUserIsStaff ||
          this.currentUserIsAccountManager ||
          this.currentUserIsPartnerManager)
      )
    },
    showMyCarriers() {
      const { isActive } = useFeatureFlag()
      return isActive('SHOW_MY-CARRIERS-PAGE').value
    }
  },
  methods: {
    handleLogoutButtonClick() {
      this.hideUserMenu()
      this.logout()
    },
    async logout() {
      await User.logout()
      this.$store.commit('resetCurrentUser')
      await this.identifyAnonymous()
      resetAnalyticsSession()
      if (this.$auth.isAuthenticated) {
        this.$auth.logout({
          returnTo: window.location.origin
        })
      } else {
        this.octopusRouter.navigateTo('/login')
      }
    },
    showUserMenu() {
      this.isUserMenuActive = true
    },
    hideUserMenu() {
      this.isUserMenuActive = false
    },
    goToCostCenter() {
      this.hideUserMenu()
      this.octopusRouter.navigateTo('/finance/cost-centre')
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/partials/variables';

$minion-default-font-size: 16px;

.navigation-menu {
  width: $octopus-menu-width;
  height: 100%;
  min-height: 100%;
  position: fixed;
  text-align: center;
  background-color: $color-primary-main;
  color: $color-layout-white;
  list-style: none;

  .link-to-homepage {
    display: inline-block;
    width: 42px;
    height: 42px;
    margin-top: 15px;
    margin-bottom: 40px;
  }

  .links-top {
    .navigation-link:not(:last-child),
    .create-order-button {
      margin-bottom: 10px;
    }
  }

  .links-bottom {
    position: fixed;
    bottom: 32px;
    padding: 0 10px;

    .navigation-link:not(:first-child) {
      margin-top: 16px;
    }
  }

  .navigation-link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $color-layout-white;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background-color: transparent;
    border: none;
    padding: 0;

    &:hover,
    &--active {
      background-color: $color-neutral-darkest;
      border-radius: 3px;
      text-decoration: none;
    }
  }

  .notification-dot {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $color-error-darker;
  }
}

.user-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  min-width: 140px;

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 8px;
    line-height: 16px;
    color: $color-neutral-darkest;
    background-color: $color-layout-white;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:hover {
      background-color: $color-neutral-lightest;
      text-decoration: none;
    }
  }

  &__icon {
    color: $color-neutral-lighter;
    font-size: 16px;
    line-height: 1;
    margin-right: 8px;
  }
}

.cost-centre-menu-item::v-deep {
  svg {
    fill: none;
    stroke: $color-neutral-main;
  }
}

.claim-link-icon {
  font-size: 24px;
}
</style>
