import { AxiosResponse } from 'axios'

import { CHARTERING_OFFICE_API_URL, CHARTERING_OFFICE_API_AUDIENCE } from '@/config'
import { transformKeysToCamelCase } from '@/services/utils/casing'

import { CharteringOfficesResponse } from '../models'

import { httpClient, IHttpClient } from './http-client'

export interface ICharteringOfficesClient {
  getCharteringOffices: () => Promise<CharteringOfficesResponse>
}

export class CharteringOfficesClient implements ICharteringOfficesClient {
  private baseUrl = () => `${CHARTERING_OFFICE_API_URL}/octopus/api`
  private audience = () => CHARTERING_OFFICE_API_AUDIENCE

  constructor(private client: IHttpClient) {}

  public getCharteringOffices = async (): Promise<CharteringOfficesResponse> => {
    const response = (await this.client.get(
      `${this.baseUrl()}/v1/chartering-offices`,
      undefined,
      { apiGatewayAuthorization: true },
      this.audience(),
      undefined
    )) as AxiosResponse<unknown>
    return transformKeysToCamelCase<CharteringOfficesResponse>(response.data)
  }
}

export const charteringOfficesClient = new CharteringOfficesClient(httpClient)
