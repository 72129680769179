import every from 'lodash/every'
import get from 'lodash/get'
import {
  CARRIER_OFFER_STATES,
  CARRIER_ASSIGNMENT_STATES,
  REJECTION_REASONS
} from '@/modules/ordering/constants'
import { humanizeTimeBetween } from '@sennder/plankton'
import { i18n } from '@/plugins/i18n'

export const getConfigurations = (allocation, isAccountManagerUser) => {
  const rejectionDescription = getRejectionDescription(
    allocation.rejectionReason,
    allocation.rejectionNote
  )
  return [
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-carrier-confirmed'),
      description: null,
      visualStatus: 'success',
      canWithdraw: false,
      conditions: {
        state: CARRIER_OFFER_STATES.ACCEPTED,
        hasBeenCarrierLocked: true,
        type: 'OFFER'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-offer-withdrawn'),
      description: null,
      visualStatus: 'disabled',
      canWithdraw: false,
      conditions: {
        state: CARRIER_OFFER_STATES.WITHDRAWN,
        type: 'OFFER'
      }
    },
    {
      text: i18n.t(
        'ordering/dispatching-list-cells-common-configs-automatically-rejected'
      ),
      description: rejectionDescription,
      visualStatus: 'error',
      canWithdraw: false,
      conditions: {
        state: CARRIER_OFFER_STATES.REJECTED,
        rejectionReason: 'AUTOMATIC_REJECTION',
        type: 'OFFER'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-offer-rejected'),
      description: rejectionDescription,
      visualStatus: 'error',
      canWithdraw: false,
      conditions: {
        state: CARRIER_OFFER_STATES.REJECTED,
        type: 'OFFER'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-offer-cancelled'),
      description: null,
      visualStatus: 'error',
      canWithdraw: false,
      conditions: {
        state: CARRIER_OFFER_STATES.CANCELLED,
        type: 'OFFER'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-assignment-pending'),
      description: i18n.t(
        'ordering/dispatching-list-cells-common-configs-assignment-pending-description',
        { time: humanizeTimeBetween(allocation.validUntil, Date.now()) }
      ),
      visualStatus: 'warning',
      canWithdraw: isAccountManagerUser,
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.PENDING,
        hasBeenCarrierLocked: false,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-assignment-pending'),
      description: i18n.t(
        'ordering/dispatching-list-cells-common-configs-assignment-pending-description',
        { time: humanizeTimeBetween(allocation.validUntil, Date.now()) }
      ),
      visualStatus: 'warning',
      canWithdraw: false,
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.AWAITING_MATCHING_CONFIRMATION,
        hasBeenCarrierLocked: false,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-accepted'),
      description: null,
      visualStatus: 'success',
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.ACCEPTED,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-expired'),
      description: null,
      visualStatus: 'error',
      canWithdraw: false,
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.EXPIRED,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-rejected'),
      description: null,
      visualStatus: 'error',
      canWithdraw: false,
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.REJECTED,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-withdrawn'),
      description: null,
      visualStatus: 'disabled',
      canWithdraw: false,
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.CANCELLED,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-no-capacity'),
      description: null,
      visualStatus: 'error',
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.NO_CAPACITY,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-not-assigned'),
      description: null,
      visualStatus: 'disabled',
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.NOT_ASSIGNED,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-carrier-blacklisted'),
      description: null,
      visualStatus: 'error',
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.CARRIER_BLACKLISTED,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t(
        'ordering/dispatching-list-cells-common-configs-domestic-carrier-restricted'
      ),
      description: null,
      visualStatus: 'error',
      conditions: {
        state: CARRIER_ASSIGNMENT_STATES.DOMESTIC_CARRIER_RESTRICTED,
        type: 'ASSIGNMENT'
      }
    },
    {
      text: i18n.t('ordering/dispatching-list-cells-common-configs-unknown-state'),
      description: null,
      visualStatus: 'disabled',
      canWithdraw: false,
      conditions: {}
    }
  ]
}

export const findConfiguration = (configurations, filters) => {
  return configurations.find(({ conditions }) => {
    return every(
      filters,
      (filterValue, filterKey) =>
        conditions[filterKey] === undefined || conditions[filterKey] === filterValue
    )
  })
}

const getRejectionDescription = (rejectionReason, rejectionNote) => {
  if (rejectionReason === 'OTHER') return rejectionNote
  const item = REJECTION_REASONS.find(item => item.id === rejectionReason)
  return get(item, 'label', null)
}
