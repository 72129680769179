import { computed } from '@vue/composition-api'

import useStore, { Store } from '@/compositions/useStore'

export const useAssignmentsStatus = () => {
  const store = useStore() as Store
  const hasPendingAssignment = computed(
    () => store.getters['assignments/hasPendingAssignment']
  )
  const hasAcceptedAssignment = computed(
    () => store.getters['assignments/hasAcceptedAssignment']
  )
  const assignments = computed(() => store.state.assignments.assignments)

  //CF: NKB-1780
  const isAssignmentActionsLocked = (isBackfill: boolean) => isBackfill

  const containsLockedAssignments = computed(() =>
    assignments.value.some(x => isAssignmentActionsLocked(x.isBackfilled))
  )

  return {
    hasPendingAssignment,
    containsLockedAssignments,
    hasAcceptedAssignment,

    isAssignmentActionsLocked
  }
}
