<template>
  <ListCell bold class="cell-carrier">
    <div>
      <span data-test="carrier-name-or-id">
        {{ name || $t('bidding/carrier-is-missing', { carrierId }) }}
      </span>
      <span>
        <img
          v-if="showOrcasLogo"
          width="16px"
          src="@/assets/images/icon-logo_orcas.svg"
          data-test="orcas-image"
          class="orcas-image"
        />
      </span>
      <span v-if="showBlacklistedLogo">
        <i
          data-test="backlisted-logo"
          class="material-icons blacklisted-logo"
          v-tooltip="$t('ordering/dispatching-list-cells-carrier-blacklisted')"
        >
          {{ $t('ordering/dispatching-list-cells-block') }}
        </i>
      </span>
      <slot></slot>
    </div>
    <div v-if="description">
      <p class="description paragraph" data-test="source">
        {{ description }}
      </p>
    </div>
  </ListCell>
</template>
<script>
import ListCell from './ListCell'
export default {
  components: { ListCell },
  props: {
    name: { type: String, required: false, default: undefined },
    showOrcasLogo: { type: Boolean, default: false },
    showBlacklistedLogo: { type: Boolean, default: false },
    description: { type: String, default: null },
    carrierId: { type: String, required: true }
  }
}
</script>

<style scoped lang="scss">
.orcas-image,
.blacklisted-logo {
  margin-left: 4px;
}

.blacklisted-logo {
  vertical-align: middle;
  font-size: 16px;
  color: $color-error-main;
  cursor: default;
}

.root {
  &.cell-carrier {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
}

.description {
  padding-top: 5px;
  color: $color-neutral-main;
}
</style>
