import { VehicleType } from '@/modules/ordering/types'

export { OrderStop, SpotCosting } from '@/services'

export const ORDER_STATES = Object.freeze([
  'NEW',
  'CREATED', // TODO: CHECK if CREATED actually exists as it used in the business logic
  'REGISTERED',
  'CARRIER_LOCKED',
  'DISPATCHED',
  'EXECUTED',
  'OPERATIONS_COMPLETED'
] as const)

export type OrderState = typeof ORDER_STATES[number]

export interface Order {
  version: number
  id: number
  loadQuantity: number
  loadUnitType: string
  loadType?: string
  loadWidth?: string
  loadHeight?: string
  loadLength?: string
  loadWeight: number
  loadMinimumTemperature: number
  loadMaximumTemperature: number
  loadDescription: string
  sealable: boolean
  codeXl: boolean
  arrivalNotification: boolean
  directTransfer: boolean
  dockLoading: boolean
  sideLoading: boolean
  topLoading: boolean
  isTemperatureControlled: boolean
  basePrice: number
  accountManagerId: number
  juniorAccountManagerId: number
  vehicleType: string
  lineCode: string
  customerContact: CustomerContact
  customerName: string
  customerId: number
  referenceNumber: string
  trackingIdForShipper?: number
  customerBillingAddress: CustomerBillingAddress
  customerCompanyAddress?: string
  state: OrderState
  needsPalletExchange: boolean
  totalNetPrice: number
  partnerManagerId?: number
  orderGroupId: number
  activePartnerManagersIds: number[]
  idForStaff: string
  isCancelled: boolean
  totalNetCost: number
  transferId: number
  licensePlate?: string
  baseCost: number
  carrierCompany?: string
  carrierContact?: string
  pickUpDate: string
  allowedVehicleTypes: VehicleType[]
  confirmationDocumentId?: string
  regularity: string
  marginPercentage?: string
  editableForVariations: boolean
  customerCompany: CustomerCompany
  carrierCompanyId?: number
  carrierContactId?: number
  workflowManaged?: boolean
}

interface CustomerContact {
  id: number
  firstName: string
  lastName: string
  email: string
  phone: string
  name: string
}

interface CustomerBillingAddress {
  id: number
  fullAddress: string
}

interface CustomerCompany {
  id: number
  name: string
}
