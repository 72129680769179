<template>
  <ListCellStatus v-bind="configuration" data-test="cell-status"></ListCellStatus>
</template>
<script>
import {
  ListCellStatus,
  findConfiguration,
  getConfigurations
} from '../../../components/list-cells'

export default {
  components: { ListCellStatus },
  props: {
    allocation: { type: Object, required: true },
    hasBeenCarrierLocked: { type: Boolean, required: true }
  },
  computed: {
    isBlacklistedCarrier() {
      return this.allocation.carrierTier === 0
    },
    configuration() {
      return findConfiguration(this.configurationList, {
        type: this.allocation.type,
        state: this.allocation.state,
        hasBeenCarrierLocked: this.hasBeenCarrierLocked,
        rejectionReason: this.allocation.rejectionReason
      })
    },
    configurationList() {
      return [
        ...getConfigurations(this.allocation, false).map(config => ({
          ...config,
          canConfirm: false
        }))
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.delete-allocation-button {
  color: $color-neutral-lighter;
}
</style>
