var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',_vm._g(_vm._b({attrs:{"disabled":_vm.inProgressRequest,"show":_vm.showDialog,"title":_vm.$t('ordering/dispatching-assignment-change-carrier-assignment-dialog-title'),"confirmButtonText":_vm.$t('ordering/dispatching-assignment-change-carrier-assignment-dialog-confirm'),"cancelButtonText":_vm.$t('global/cancel'),"inPlace":""},on:{"close":_vm.handleCancelEvent,"cancel":_vm.handleCancelEvent,"confirm":_vm.handleConfirmEvent}},'Dialog',_vm.$attrs,false),_vm.$listeners),[_c('p',{staticClass:"assign-description"},[_c('i18n',{attrs:{"path":"ordering/dispatching-assignment-change-carrier-assignment-dialog-content"}},[_c('span',{staticClass:"modal-bold"},[_vm._v(" "+_vm._s(_vm.$t('ordering/ordering-dispatch-form-shared-order-number', [_vm.idForStaff]))+" ")]),_c('span',{staticClass:"modal-bold"},[_vm._v(_vm._s(_vm.carrierCompany && _vm.carrierCompany.name))])])],1),_c('Form',{ref:"form",attrs:{"disabled":_vm.inProgressRequest}},[_c('div',{staticClass:"plankton-grid"},[_c('div',{staticClass:"plankton-cell",attrs:{"span":"18"}},[_c('CarrierContactDropdownField',{attrs:{"carrierId":_vm.carrierCompany && _vm.carrierCompany.id,"required":"","inlineError":"","title":_vm.$t(
              'ordering/dispatching-assignment-change-carrier-assignment-dialog-carrier-contact-title'
            ),"fetchFromNewEndpoint":_vm.shouldUseNewEndpoints,"data-test":"carrier-contact-field"},model:{value:(_vm.carrierContact),callback:function ($$v) {_vm.carrierContact=$$v},expression:"carrierContact"}})],1),_c('div',{staticClass:"plankton-cell",attrs:{"span":"6"}},[_c('NumericInputField',{attrs:{"inputValidators":_vm.validityValidators,"placeholder":_vm.$t(
              'ordering/dispatching-assignment-change-carrier-assignment-dialog-numericinput-placeholder'
            ),"textIcon":_vm.$t(
              'ordering/dispatching-assignment-change-carrier-assignment-dialog-numericinput-text-icon'
            ),"title":_vm.$t(
              'ordering/dispatching-assignment-change-carrier-assignment-dialog-numericinput-title'
            ),"data-test":"validity-field"},model:{value:(_vm.validity),callback:function ($$v) {_vm.validity=$$v},expression:"validity"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }