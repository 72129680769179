import { AxiosResponse } from 'axios'

import { CARRIER_PROFILE_API_AUDIENCE, CARRIER_PROFILE_API_URL } from '@/config'

import {
  CarrierEntity,
  CarrierResponse,
  ContactResponse,
  PaginationResponse
} from '../models'

import { IHttpClient, httpClient } from './http-client'
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase
} from '@/services/utils/casing'

export interface ICarrierProfileClient {
  getContacts: (
    carrierId?: string,
    offset?: number,
    limit?: number
  ) => Promise<PaginationResponse<ContactResponse>>
  getCarrier: (id: string) => Promise<CarrierEntity>
  searchCarriers: (searchText: string) => Promise<CarrierEntity[]>
}

export class CarrierProfileClient implements ICarrierProfileClient {
  private baseUrl = () => `${CARRIER_PROFILE_API_URL}`
  private audience = () => CARRIER_PROFILE_API_AUDIENCE

  constructor(private client: IHttpClient) {}

  public getContacts = async (
    carrierId?: string,
    offset?: number,
    limit?: number
  ): Promise<PaginationResponse<ContactResponse>> => {
    const results = (await this.client.get(
      `${this.baseUrl()}/v1/contacts`,
      transformKeysToSnakeCase({
        carrierId,
        offset,
        limit: limit ?? 100,
        showDeleted: false
      }),
      { apiGatewayAuthorization: true, arrayFormat: 'repeat' },
      this.audience(),
      'carriers:list-contacts'
    )) as AxiosResponse<PaginationResponse<ContactResponse>>

    return transformKeysToCamelCase<PaginationResponse<ContactResponse>>(results.data)
  }

  public getCarrier = async (id: string): Promise<CarrierEntity> => {
    const result = (await this.client.get(
      `${this.baseUrl()}/v1/carriers/${id}`,
      undefined,
      { apiGatewayAuthorization: true, arrayFormat: 'repeat' },
      this.audience(),
      'carriers:read'
    )) as AxiosResponse<CarrierResponse>

    return result && result.data ? transformKeysToCamelCase(result.data) : null
  }

  public searchCarriers = async (searchText: string): Promise<CarrierEntity[]> => {
    const result = (await this.client.get(
      `${this.baseUrl()}/v1/carriers/search`,
      transformKeysToSnakeCase({
        companyName: searchText,
        limit: 20
      }),
      { apiGatewayAuthorization: true, arrayFormat: 'repeat' },
      this.audience(),
      'carriers:list'
    )) as AxiosResponse<CarrierResponse>

    return result && result.data && result.data.results
      ? transformKeysToCamelCase(result.data.results)
      : null
  }
}

export const carrierProfileClient = new CarrierProfileClient(httpClient)
