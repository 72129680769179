




















































































































































































































import { PropType, computed, defineComponent, ref } from '@vue/composition-api'
import uuidv4 from 'uuid/v4'

import { humanizeIsoDateTimeWithDots } from '@sennder/plankton'

import { formatMoneyWithDashForNull } from '@/global-setup/filters'

import useFeatureFlag from '@/compositions/useFeatureFlag'

import {
  AllocationList,
  AllocationListPlaceholder
} from '../../components/allocation-list'
import { ListCell, ListCellCarrier, ListCellQuality } from '../../components/list-cells'
import { useAssignments, useAssignmentsStatus } from '../../compositions'

import {
  ASSIGNMENTS_FEATURE_FLAGS,
  CARRIER_ASSIGNMENT_STATES,
  CARRIER_OFFER_STATES
} from '../../constants'
import { Bid, Order, OrderStop, SpotCosting } from '../../models'

import { ListCellStatusAccountManager } from './list-cells'
import { CancelCarrierAssignmentModal, ChangeCarrierAssignmentModal } from './modals'

export default defineComponent({
  name: 'AccountManagerAssignments',
  components: {
    AllocationList,
    AllocationListPlaceholder,
    ListCell,
    ListCellCarrier,
    ListCellQuality,
    ListCellStatusAccountManager,
    CancelCarrierAssignmentModal,
    ChangeCarrierAssignmentModal
  },
  props: {
    order: { type: Object as PropType<Order>, required: true },
    orderStops: { type: Array as PropType<OrderStop[]>, required: true },
    shipmentId: { type: String, required: true },
    hasBeenCarrierLocked: { type: Boolean, required: true },
    spotCosting: { type: Object as PropType<SpotCosting>, required: true },
    offerList: { type: Array as PropType<Bid[]>, required: true }
  },
  setup(props) {
    const cancelCarrierAssignmentModal = ref(null)
    const changeCarrierAssignmentModal = ref(null)
    const { isActive } = useFeatureFlag()

    const {
      showView,
      errorWhileFetchingAssignments,
      assignments,
      pendingAssignment,
      assignmentTypes,
      carrierKpi,
      refetch
    } = useAssignments(props)
    const { containsLockedAssignments, hasPendingAssignment, isAssignmentActionsLocked } =
      useAssignmentsStatus()

    const isTargetDeviationEnabled = computed(
      () =>
        isActive(ASSIGNMENTS_FEATURE_FLAGS.ENABLE_ORDER_DETAILS_TARGET_DEVIATION).value
    )

    const carrierAllocationAssignments = computed(() =>
      assignments.value.map(assignment => ({
        assignmentType: assignment.assignmentType,
        type: 'ASSIGNMENT',
        //Automatic assignment created by cascading system doesn't have ids
        //so we generate one for the key loop used by vuejs
        id: assignment.id || uuidv4(),
        companyId: assignment.company.companyId || assignment.company.mothershipId,
        companyName: assignment.company.name,
        carrierTier: null,
        isOrcasRequest: false,
        creatorName: assignment.createdBy?.name,
        price: assignment.baseCost,
        owner: assignment.owner,
        state: assignment.state,
        created: assignment.created,
        marginPercentage: assignment.marginPercentage,
        rejectionReason: null,
        rejectionDescription: null,
        modified: null,
        validUntil: assignment.validUntil,
        carrierKpis: carrierKpi.value(assignment.company.mothershipId),
        isBackfill: assignment.isBackfilled,
        currency: assignment.currency
      }))
    )

    const marginPercentageClass = allocation => {
      if (Math.round(allocation.marginPercentage) >= 5) {
        return 'margin-ok'
      }
      if (Math.round(allocation.marginPercentage) > 0) {
        return 'margin-warning'
      }
      if (Math.round(allocation.marginPercentage) <= 0) {
        return 'margin-error'
      }

      return 'margin-unknown'
    }

    const shouldShowAssignmentInfoTooltip = allocation => {
      if (allocation.state !== CARRIER_ASSIGNMENT_STATES.PENDING || !allocation.created) {
        return false
      }

      //check if their is another allocation pending after this one
      return !assignments.value.some(
        assignment => assignment.state === CARRIER_ASSIGNMENT_STATES.NOT_ASSIGNED
      )
    }
    const targetDeviationPercentage = allocation => {
      if (allocation.price && props.spotCosting.suggestedBaseCost) {
        return (
          ((props.spotCosting.suggestedBaseCost - allocation.price) /
            props.spotCosting.suggestedBaseCost) *
          100
        )
      }
      return null
    }
    const targetDeviationPercentageClass = allocation => {
      const allocationPercentage = targetDeviationPercentage(allocation)
      if (allocationPercentage) {
        if (Math.round(allocationPercentage) >= 0) {
          return 'target-deviation-ok'
        }
        if (Math.round(allocationPercentage) < 0) {
          return 'target-deviation-error'
        }
      }
      return ''
    }

    const cancelPendingAssignment = async () => {
      const result = await cancelCarrierAssignmentModal.value?.show({
        carrierCompany: pendingAssignment.value.company,
        assignmentId: pendingAssignment.value.id,
        idForStaff: props.order.idForStaff
      })
      if (result === 'OK') await refetch()
    }

    const assignOffer = async allocation => {
      const result = await changeCarrierAssignmentModal.value?.show({
        carrierCompany: { name: allocation.companyName, id: allocation.companyId },
        carrierContact: null,
        baseCost: allocation.price
      })
      if (result === 'OK') await refetch()
    }
    const hasAmAcceptedAnyOffer = computed(() => {
      return props.offerList.some(({ state }) => state === CARRIER_OFFER_STATES.ACCEPTED)
    })

    const onSubmit = async () => {
      await refetch()
    }

    return {
      showView,
      errorWhileFetchingAssignments,
      carrierAllocationAssignments,
      pendingAssignment,
      hasPendingAssignment,
      hasAmAcceptedAnyOffer,
      containsLockedAssignments,
      assignmentTypes,

      marginPercentageClass,
      shouldShowAssignmentInfoTooltip,
      targetDeviationPercentage,
      targetDeviationPercentageClass,

      humanizeIsoDateTimeWithDots,
      formatMoneyWithDashForNull,

      isAssignmentActionsLocked,
      cancelPendingAssignment,
      assignOffer,

      changeCarrierAssignmentModal,
      cancelCarrierAssignmentModal,

      isTargetDeviationEnabled,

      onSubmit
    }
  }
})
