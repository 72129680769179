import usePageVisibility from './usePageVisibility'

export default (callback: () => void, refreshRate = 60000) => {
  let timeoutId: number

  const start = () => {
    callback()
    timeoutId = window.setInterval(callback, refreshRate)
  }

  const stop = () => clearInterval(timeoutId)

  usePageVisibility(start, stop)
}
