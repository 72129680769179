<template>
  <div>
    <div class="plankton-grid">
      <NumericInputFilter
        filterName="activePartnerManagersCount"
        :maxValue="10"
        :allowDecimal="1"
        :allowInteger="2"
        data-test="activePartnerManagersCount"
        :title="$t('filters/active-partner-managers-filter-title')"
        class="plankton-cell"
        :textIcon="$t('filters/active-partner-managers-filter-text-icon')"
        span="24"
      />
    </div>
  </div>
</template>
<script>
import NumericInputFilter from '../NumericInputFilter/NumericInputFilter'
export default {
  components: {
    NumericInputFilter
  },
  inject: ['getFilterValue', 'setFilterValue'],
  computed: {
    activePartnerManagersCount: function () {
      return this.getFilterValue('activePartnerManagersCount')
    }
  }
}
</script>
