import { GetterTree } from 'vuex'

import { AssignmentState, CarrierKpi, CompanyType, InternalAssignment } from '../models'

import { IAssignmentsState } from './state'

export type AssignmentsGetter = (state: IAssignmentsState) => InternalAssignment[]
export const assignments: AssignmentsGetter = state =>
  state.assignments.map(x =>
    x.company.type === CompanyType.CHARTERING_OFFICE
      ? {
          ...x,
          company: {
            ...x.company,
            name: state.charteringOffices.find(y => y.uuid === x.company.companyId)?.name
          }
        }
      : x
  )

// TODO: [refactor] logic to have a better naming, something like: hasAssignmentsInProgress
export type PendingAssignmentGetter = (state: IAssignmentsState) => InternalAssignment
export const pendingAssignment: PendingAssignmentGetter = state =>
  state.assignments.find(
    x =>
      x.state === AssignmentState.PENDING ||
      x.state === AssignmentState.AWAITING_MATCHING_CONFIRMATION
  )

export type HasPendingAssignmentGetter = (_: unknown, getters: GettersType) => boolean
export const hasPendingAssignment: HasPendingAssignmentGetter = (_, getters) =>
  Boolean(getters.pendingAssignment)

export type AcceptedAssignmentGetter = (state: IAssignmentsState) => InternalAssignment
export const acceptedAssignment: AcceptedAssignmentGetter = state =>
  state.assignments.find(x => x.state === AssignmentState.ACCEPTED)

export type HasAcceptedAssignmentGetter = (_: unknown, getters: GettersType) => boolean
export const hasAcceptedAssignment: HasAcceptedAssignmentGetter = (_, getters) =>
  Boolean(getters.acceptedAssignment)

export type CarrierKpiGetter = (
  state: IAssignmentsState
) => (carrierId: number) => CarrierKpi
export const carrierKpi: CarrierKpiGetter = state => carrierId => {
  return state.carriersKpi?.length
    ? state.carriersKpi.find(t => t.carrierId === carrierId)
    : null
}

export type CarrierMothershipIdsGetter = (state: IAssignmentsState) => number[]
export const carrierMothershipIds: CarrierMothershipIdsGetter = state => [
  ...new Set(state.assignments.map(x => x.company.mothershipId).filter(x => !!x))
]

export type ShipmentIdGetter = (
  _: unknown,
  __: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rootState: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rootGetters: any
) => string | null
export const shipmentId: ShipmentIdGetter = (_, __, rootState, rootGetters) => {
  return rootGetters['ordering/containsShipmentsAndIsShipperPilot']
    ? rootState.ordering.shipmentData[0]?.id
    : null
}

export type GettersType = {
  assignments: AssignmentsGetter
  pendingAssignment: PendingAssignmentGetter
  hasPendingAssignment: HasPendingAssignmentGetter
  acceptedAssignment: AcceptedAssignmentGetter
  hasAcceptedAssignment: HasAcceptedAssignmentGetter
  carrierKpi: CarrierKpiGetter
  carrierMothershipIds: CarrierMothershipIdsGetter
  shipmentId: ShipmentIdGetter
}

// TODO: type root store when it'll be typed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getters: GetterTree<IAssignmentsState, any> & GettersType = {
  assignments,
  pendingAssignment,
  hasPendingAssignment,
  acceptedAssignment,
  hasAcceptedAssignment,
  carrierKpi,
  carrierMothershipIds,
  shipmentId
}
