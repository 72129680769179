









































import {
  computed,
  defineComponent,
  PropType,
  ref,
  onMounted,
  onUnmounted
} from '@vue/composition-api'
import { polling } from '@sennder/senn-node-toolkit'

import useI18n from '@/compositions/useI18n'
import useStore from '@/compositions/useStore'
import useCurrentUser from '@/compositions/useCurrentUser'
import { hasHadState } from '@/modules/common/order-states'
import ActivePartnerManagersModal from '@/modules/common/components/ActivePartnerManagersModal.vue'
import { DispatchingOrderDetail } from '@/modules/ordering/components/dispatching/types'
import { OrderDetailWithCustomerCompany } from '@/services'

import DispatchingForm from './DispatchingForm.vue'
import AllocationList from './carrier-allocations/AllocationList.vue'
import { POLLING_TIMEOUT } from './constants'

import {
  subscribe,
  BidAccepted,
  MicrofrontendEvents
} from '@sennder/senn-node-microfrontend-event-bus'

export default defineComponent({
  components: {
    DispatchingForm,
    AllocationList,
    ActivePartnerManagersModal
  },
  props: {
    order: { type: Object as PropType<DispatchingOrderDetail>, required: true },
    stops: { type: Array, required: true }
  },
  setup(props) {
    const { t } = useI18n()
    const store = useStore()
    const {
      currentUserIsAccountManager,
      currentUserIsPartnerManager,
      currentUserIsDirectDispatcher
    } = useCurrentUser()
    const dispatchFormShown = ref(false)
    const showActivePM = ref(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const activePartnerManagersModal = ref<any>(null)
    const stopRefetching = ref<() => void>(() => {})

    const eventsAbortController = new AbortController()

    subscribe<BidAccepted>(
      MicrofrontendEvents.BidAccepted,
      (data: BidAccepted) => {
        if (data.orderId !== props.order.id) return
        showDispatchingForm()
      },
      { signal: eventsAbortController.signal }
    )

    const activeManagersLength = computed(
      () => props.order.activePartnerManagersIds?.length || 0
    )
    const toggleLinkText = computed(() => {
      if (dispatchFormShown.value)
        return t('ordering/ordering-dispatch-see-offer-or-history')
      else return t('ordering/ordering-dispatch-go-to-dispatch-info')
    })
    const hasHadCarrierLockedState = computed(() => {
      return hasHadState(props.order.state || 'NEW', 'CARRIER_LOCKED')
    })
    const hasHadRegisteredState = computed(() => {
      return hasHadState(props.order.state || 'NEW', 'REGISTERED')
    })

    const toggleView = () => {
      dispatchFormShown.value = !dispatchFormShown.value
    }

    const openActivePartnerManagerModal = () => {
      activePartnerManagersModal.value?.show()
    }

    const showDispatchingForm = () => {
      const { stopPolling } = polling<OrderDetailWithCustomerCompany>(
        async () => {
          return await store.dispatch('ordering/getOrder', props.order.id)
        },
        {
          timeout: POLLING_TIMEOUT,
          stopCallback: ({ payload }) => {
            return payload.state === 'CARRIER_LOCKED'
          },
          onPollingCompleted: async (_, reason) => {
            if (reason === 'condition') {
              await store.dispatch('ordering/fetchCompleteOrder', props.order.id)
            }
          }
        }
      )

      dispatchFormShown.value = true

      stopRefetching.value = stopPolling
    }

    onMounted(() => {
      dispatchFormShown.value = hasHadCarrierLockedState.value
    })
    onUnmounted(() => {
      stopRefetching.value()
      eventsAbortController.abort()
    })

    return {
      dispatchFormShown,
      showActivePM,
      toggleLinkText,
      hasHadCarrierLockedState,
      hasHadRegisteredState,
      activePartnerManagersModal,
      activeManagersLength,

      toggleView,
      openActivePartnerManagerModal,
      showDispatchingForm,

      currentUserIsPartnerManager,
      currentUserIsAccountManager,
      currentUserIsDirectDispatcher
    }
  }
})
