import { computed } from '@vue/composition-api'

import useStore, { Store } from '@/compositions/useStore'

export const useCharteringOffices = () => {
  const store = useStore() as Store

  const refetch = async () => {
    await store.dispatch('assignments/fetchCharteringOffices')
  }

  const charteringOffices = computed(() => store.state.assignments.charteringOffices)

  refetch()

  return {
    charteringOffices
  }
}
