<template>
  <div
    v-if="!loading && order && order.id"
    :key="componentKey"
    :class="{
      'ordering-root': true,
      'ordering-root--full-screen': enableFullScreen
    }"
  >
    <!--
      Octopus Bidding µFE loader - START
      Modify only after discussing with POD-Discovery team please
      Unlike other µFEs which is loaded as a separate page, this one needs to soft load (with no UI at first)
      Later on, each component can decide where to load the bidding components using a portal target.
     -->
    <BiddingMicrofrontendLoader :orderId="order.id" />
    <!-- Octopus Bidding µFE loader - END -->
    <OrderInformationHeaderV3
      v-if="isNewV3OrderHeaderEnabled && isShipmentLinkedToOrder"
      :order="order"
      :stops="orderStops"
      :spotCosting="spotCosting"
      :subContractingPolicy="subContractingPolicy"
      :routeSummary="routeSummary"
      :referenceNumber="computedOrderFromShipmentV3.referenceNumber"
      :trackingIdForShipper="computedOrderFromShipmentV3.trackingIdForShipper"
      :regularity="computedOrderFromShipmentV3.regularity"
      :shipperSensitivity="shipperSensitivity"
      :shipmentId="shipmentId"
    />
    <OrderInformationHeader
      v-else
      :order="order"
      :stops="orderStops"
      :spotCosting="spotCosting"
      :subContractingPolicy="subContractingPolicy"
      :shipperSensitivity="shipperSensitivity"
      :shipmentId="shipmentId"
    />
    <div class="content-wrapper">
      <OrderSidebarV3
        v-if="
          isNewV3OrderSidebarEnabled &&
          isShipmentLinkedToOrder &&
          visibleState !== 'NEW' &&
          visibleState !== 'PLANNING'
        "
        key="1"
        :loading="!isShipmentStopsLoaded"
        :order="computedOrderFromShipmentV3"
        :stops="shipmentStops"
        :totalDistance="totalDistance"
        class="sidebar-content"
      />
      <OrderSidebar
        v-else-if="
          visibleState !== 'NEW' &&
          visibleState !== 'PLANNING' &&
          orderStops &&
          orderStops.length
        "
        key="1"
        :order="order"
        :stops="orderStops"
        class="sidebar-content"
      />
      <OrderStageContent
        :order="order"
        :stops="orderStops"
        :accountManager="accountManager"
        :juniorAccountManager="juniorAccountManager"
        :partnerManager="partnerManager"
        :spotBidder="spotBidder"
        @update-order-regularity="onUpdateOrderRegularity"
      />
    </div>
    <OrderActivitySidebar
      :orderId="order.id"
      :currentUserId="currentUserId"
      :stops="orderStops"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import orderingMappers from '@/modules/ordering/store/mappers'
import shipmentMappers from '@/modules/shipment/store/mappers'
import OrderInformationHeader from './shared/header/OrderInformationHeader'
import OrderInformationHeaderV3 from './shared/header/OrderInformationHeaderV3'
import OrderSidebar from './shared/order-sidebar/OrderSidebar'
import OrderSidebarV3 from './shared/order-sidebar/OrderSidebarV3'
import { OrderActivitySidebar } from '@/modules/order-activity-sidebar'
import OrderStageContent from './shared/OrderStageContent'
import { fetchOrderId } from '@/services/ordering-service'
import { Currency } from '@/modules/common'
import { monitorError } from '@/analytics/monitoring'
import commonMappers from '@/modules/common/store/mappers'
import { Actions as CommonActions } from '@/modules/common/store'
import BiddingMicrofrontendLoader from '@/microfrontends/pages/bidding/loader/BiddingMicrofrontendLoader.vue'
import { defineComponent } from '@vue/composition-api'
import { MICRO_FRONTEND_MODE } from '@/config'
import useFeatureFlag from '@/compositions/useFeatureFlag'
import { useShipment } from '@/modules/shipment/compositions/useShipment'

export default defineComponent({
  components: {
    OrderInformationHeader,
    OrderInformationHeaderV3,
    OrderSidebar,
    OrderSidebarV3,
    OrderStageContent,
    OrderActivitySidebar,
    BiddingMicrofrontendLoader
  },
  async beforeRouteUpdate(to, from, next) {
    this.resetState()
    await this.loadOrder(to.params)
    this.reloadContent()
    next()
  },
  beforeRouteLeave(to, from, next) {
    this.resetState()
    next()
  },
  data() {
    return {
      componentKey: 0,
      loading: false,
      isShipmentLinkedToOrder: false,
      shipmentId: ''
    }
  },
  head: {
    title() {
      const first = this.firstStop
        ? `${this.firstStop.countryCode}-${this.firstStop.zipCode}`
        : ''
      const last = this.lastStop
        ? `${this.lastStop.countryCode}-${this.lastStop.zipCode}`
        : ''

      const separator = first && last ? '->' : ''
      const inner = first && last ? first : this.order.idForStaff

      return {
        inner,
        separator,
        complement: last
      }
    }
  },
  computed: {
    ...orderingMappers.mapState([
      'visibleState',
      'order',
      'orderStops',
      'spotCosting',
      'accountManager',
      'juniorAccountManager',
      'partnerManager',
      'spotBidder',
      'shipmentData',
      'shipperProfile'
    ]),
    ...shipmentMappers.mapState([
      'shipmentStops',
      'isShipmentStopsLoaded',
      'shipmentRoute'
    ]),
    ...shipmentMappers.mapGetters(['computedOrderFromShipmentV3']),
    ...mapGetters(['currentUserId', 'currentUserEmail']),
    totalDistance() {
      return this.shipmentRoute?.estimated_length_in_km
    },
    firstStop() {
      return this.orderStops[0]?.warehouseAddress
    },
    lastStop() {
      return this.orderStops[this.orderStops.length - 1]?.warehouseAddress
    },
    enableFullScreen() {
      return !MICRO_FRONTEND_MODE
    },
    isNewV3OrderSidebarEnabled() {
      const { isActive } = useFeatureFlag()
      return isActive('ENABLE_NEW-V3-ORDER-SIDEBAR')?.value
    },
    isNewV3OrderHeaderEnabled() {
      const { isActive } = useFeatureFlag()
      return isActive('ENABLE_NEW-V3-ORDER-HEADER')?.value
    },
    routeSummary() {
      const stopsShortZipCodes = this.shipmentStops.map(({ warehouseAddress }) => {
        if (
          !warehouseAddress ||
          (!warehouseAddress.zipCode && !warehouseAddress.countryCode)
        ) {
          return 'N/A'
        }
        const shortZipCode = warehouseAddress.zipCode
          ? warehouseAddress.zipCode.substring(0, 2)
          : ''
        return `${warehouseAddress.countryCode || ''}${shortZipCode}`
      })

      return {
        fullRoute: stopsShortZipCodes.join(' > '),
        firstStop: stopsShortZipCodes[0],
        lastStop: stopsShortZipCodes[stopsShortZipCodes.length - 1],
        extraCount: stopsShortZipCodes.length < 5 ? 0 : stopsShortZipCodes.length - 2
      }
    },
    /**
     * Vue 2.6 doesn't support optional chaining in templates D:
     */
    subContractingPolicy() {
      return this.shipperProfile?.subContractingPolicy
    },
    shipperSensitivity() {
      return this.shipperProfile?.shipperSensitivity
    }
  },
  watch: {
    order() {
      this.$emit('updateHead')
    },
    orderStops() {
      this.$emit('updateHead')
    }
  },
  async created() {
    this.loading = true
    try {
      await this.loadOrder(this.$route.params)

      if (this.isNewV3OrderSidebarEnabled || this.isNewV3OrderHeaderEnabled) {
        const { getShipmentIdByOrderId } = useShipment()
        const shipmentId = await getShipmentIdByOrderId(this.order.id)
        if (shipmentId) {
          this.isShipmentLinkedToOrder = true
          this.shipmentId = shipmentId
          await this.fetchShipmentV3(shipmentId)
          this.updateDefaultVisibleOrderState()
        } else {
          this.isShipmentLinkedToOrder = false
        }
      }
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...orderingMappers.mapMutations(['resetState']),
    ...orderingMappers.mapActions([
      'fetchCompleteOrder',
      'updateDefaultVisibleOrderState',
      'updateFacilityPlan'
    ]),
    ...shipmentMappers.mapActions(['fetchShipmentV3']),
    ...commonMappers.mapActions([CommonActions.updateExchangeRate]),
    reloadContent() {
      this.componentKey++
    },
    onUpdateOrderRegularity(contractType) {
      this.order.regularity = contractType
    },
    async loadOrder({ orderGroupId, letter }) {
      const orderId = await fetchOrderId(orderGroupId, letter)
      await this.fetchCompleteOrder(orderId)
      try {
        await this.updateExchangeRate(Currency.EUR)
      } catch (error) {
        /**
         * It's ok for this to fail, but we want to record this.
         */
        monitorError(error)
      }
      this.updateDefaultVisibleOrderState()
    }
  }
})
</script>

<style scoped lang="scss">
@import 'src/modules/ordering/variables';

.ordering-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  background-color: $color-layout-white;
  padding-bottom: $ordering-footer-height;

  &--full-screen {
    height: 100vh;
  }
}

.content-wrapper {
  display: flex;
  flex-grow: 1;
  overflow: auto;
}
</style>
