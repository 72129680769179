import get from 'lodash/get'
import getInitialState from './state'
import { fullName } from '@/modules/common/filters/person-filters'
import { sortVehicleTypes } from '@sennder/octopus-constants'

const mutations = {
  setOrder(state, order) {
    state.order = {
      ...order,
      carrierCompanyId: order.carrierCompanyId || get(order, 'carrierCompany.id', null),
      carrierContactId: order.carrierContactId || get(order, 'carrierContact.id', null)
    }
    if (order.carrierContact) {
      state.order.carrierContact = {
        ...order.carrierContact,
        name: fullName(order.carrierContact)
      }
    }
    if (order.customerContact) {
      state.order.customerContact = {
        ...order.customerContact,
        name: fullName(order.customerContact)
      }
    }
    // display vehicle types everywhere with predefined sorting order
    if (order.allowedVehicleTypes) {
      state.order.allowedVehicleTypes = sortVehicleTypes(state.order.allowedVehicleTypes)
    }
  },

  setShipperProfile(state, shipperProfile) {
    state.shipperProfile = shipperProfile
  },

  setOrderStops(state, stops = []) {
    state.orderStops = stops
  },

  setShipmentData(state, shipmentData) {
    if (shipmentData.shipments) {
      state.shipmentData = shipmentData.shipments
    }
  },
  setTransportOfferId(state, transportOfferId) {
    if (transportOfferId) {
      state.transportOfferId = transportOfferId
    }
  },

  setExecutionPlanData(state, executionPlanData) {
    state.executionPlanData = executionPlanData
  },

  setSpotCosting(state, spotCosting) {
    state.spotCosting = {
      suggestedBaseCost: null,
      costSuggestionCategory: null,
      confidenceLevel: null,
      suggestedBaseCostIncentives: null,
      suggestedBaseCostOctopus: null,
      displayCost: null,
      ...spotCosting
    }
  },

  setAccountManager(state, accountManager) {
    if (accountManager.mothershipId) {
      accountManager.id = accountManager.mothershipId
    }
    state.order.accountManagerId = accountManager.id
    state.accountManager = {
      ...accountManager,
      fullName: fullName(accountManager),
      name: fullName(accountManager)
    }
  },

  setJuniorAccountManager(state, juniorAccountManager) {
    if (juniorAccountManager.mothershipId) {
      juniorAccountManager.id = juniorAccountManager.mothershipId
    }
    state.order.juniorAccountManagerId = juniorAccountManager.id
    state.juniorAccountManager = {
      ...juniorAccountManager,
      fullName: fullName(juniorAccountManager),
      name: fullName(juniorAccountManager)
    }
  },

  setPartnerManager(state, partnerManager) {
    if (partnerManager.mothershipId) {
      partnerManager.id = partnerManager.mothershipId
    }
    state.order.partnerManagerId = partnerManager.id
    state.partnerManager = {
      ...partnerManager,
      fullName: fullName(partnerManager),
      name: fullName(partnerManager)
    }
  },

  setSpotBidder(state, spotBidder) {
    if (spotBidder.mothershipId) {
      spotBidder.id = spotBidder.mothershipId
    }
    state.order.spotBidderId = spotBidder.id
    state.spotBidder = {
      ...spotBidder,
      fullName: fullName(spotBidder),
      name: fullName(spotBidder)
    }
  },

  setVisibleState(state, orderState) {
    state.visibleState = orderState
  },

  changeDirtyPlanningForm(state, value) {
    state.dirtyPlanningForm = value
  },

  setAccountManagerOfferList(state, offerList) {
    state.accountManagerOfferList = offerList
  },

  setCarriersKpi(state, carriersKpi) {
    state.carriersKpi = carriersKpi
  },

  setCarrierTrackingRate(state, { carrierId, rate }) {
    state.carriersTrackingRate[carrierId] = { rate }
  },

  setPartnerManagerOfferList(state, offerList) {
    state.partnerManagerOfferList = offerList
  },

  setTransfer(state, transfer) {
    state.transfer = transfer
  },
  setTrackingConfig(state, config = {}) {
    state.trackingConfig = {
      ...state.trackingConfig,
      ...config
    }
  },
  setAssignments(state, assignments) {
    state.assignments = assignments
  },
  setIsShipmentFeasible(state, payload) {
    state.isShipmentFeasible = payload
  },
  setIsShipmentFeasibleLoading(state, payload) {
    state.isShipmentFeasibleLoading = payload
  },
  setIsShipmentReschedulingBlockedByChartering(state, isBlocked) {
    state.isShipmentReschedulingBlockedByChartering = isBlocked
  },
  setFrontLink(state, frontLink) {
    state.frontLink = frontLink
  },
  resetState(state) {
    Object.assign(state, getInitialState())
  }
}

export default mutations
