<template>
  <ListCellStatus v-bind="configuration" data-test="cell-status">
    <fragment>
      <Button
        v-tooltip="{
          content: $t(
            'ordering/dispatching-list-cells-account-manager-withdraw-assignment'
          ),
          delay: { show: 500 }
        }"
        v-if="configuration.canWithdraw && !lockAssignmentActions"
        inline
        materialIcon="delete"
        category="tertiary"
        data-test="withdraw-allocation"
        @click="$emit('withdraw')"
      />
      <Button
        v-tooltip="{
          content: disableAssignOption
            ? $t(
                'ordering/dispatching-list-cells-account-manager-mothership-assignment-not-supported'
              )
            : $t('ordering/dispatching-list-cells-account-manager-assign-offer'),
          delay: { show: 500 }
        }"
        v-if="configuration.canAssign && !lockAssignmentActions"
        inline
        :disabled="disableAssignOption"
        materialIcon="check"
        category="primary"
        data-test="assign-offer"
        @click="$emit('assign')"
      />
    </fragment>
  </ListCellStatus>
</template>
<script>
import {
  ListCellStatus,
  findConfiguration,
  getConfigurations
} from '../../../components/list-cells'
import { CARRIER_ASSIGNMENT_STATES } from '../../../constants'

// FIXME: [assignments] remove bidding actions that unrelated to assignments.
export default {
  components: { ListCellStatus },
  props: {
    allocation: { type: Object, required: true },
    hasAmAcceptedAnyOffer: { type: Boolean, required: true },
    hasPendingAssignment: { type: Boolean, required: true },
    hasBeenCarrierLocked: { type: Boolean, required: true },
    disableAcceptOption: { type: Boolean, default: false },
    disableAssignOption: { type: Boolean, default: false },
    disableRejectOption: { type: Boolean, default: false },
    lockAssignmentActions: { type: Boolean, default: false }
  },
  computed: {
    isBlacklistedCarrier() {
      return this.allocation.carrierTier === 0
    },
    configuration() {
      return findConfiguration(this.configurationList, {
        state: this.allocation.state,
        hasBeenCarrierLocked: this.hasBeenCarrierLocked,
        hasAmAcceptedAnyOffer: this.hasAmAcceptedAnyOffer,
        rejectionReason: this.allocation.rejectionReason,
        type: this.allocation.type
      })
    },
    // TODO: [assignments] Can be refactored to work only with assignments
    configurationList() {
      return [
        {
          text: this.$t('ordering/dispatching-list-cells-account-manager-expired'),
          description: null,
          visualStatus: 'error',
          canAssign: true,
          canWithdraw: false,
          conditions: {
            state: CARRIER_ASSIGNMENT_STATES.EXPIRED,
            hasBeenCarrierLocked: false,
            hasAmAcceptedAnyOffer: false,
            type: 'ASSIGNMENT'
          }
        },
        {
          text: this.$t('ordering/dispatching-list-cells-account-manager-not-assigned'),
          description: null,
          visualStatus: 'disabled',
          canAssign: true,
          canWithdraw: false,
          conditions: {
            state: CARRIER_ASSIGNMENT_STATES.NOT_ASSIGNED,
            hasBeenCarrierLocked: false,
            hasAmAcceptedAnyOffer: false,
            type: 'ASSIGNMENT'
          }
        },
        {
          text: this.$t('ordering/dispatching-list-cells-account-manager-no-capacity'),
          description: null,
          visualStatus: 'error',
          canAssign: true,
          canWithdraw: false,
          conditions: {
            state: CARRIER_ASSIGNMENT_STATES.NO_CAPACITY,
            hasBeenCarrierLocked: false,
            hasAmAcceptedAnyOffer: false,
            type: 'ASSIGNMENT'
          }
        },
        {
          text: this.$t('ordering/dispatching-list-cells-account-manager-withdrawn'),
          description: null,
          visualStatus: 'disabled',
          canAssign: true,
          canWithdraw: false,
          conditions: {
            state: CARRIER_ASSIGNMENT_STATES.CANCELLED,
            hasBeenCarrierLocked: false,
            hasAmAcceptedAnyOffer: false,
            type: 'ASSIGNMENT'
          }
        },
        ...getConfigurations(this.allocation, true).map(config => ({
          ...config,
          canReject: false,
          canAccept: false
        }))
      ]
    }
  }
}
</script>
