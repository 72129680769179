import uuidv4 from 'uuid/v4'

import { Currency } from '@/modules/common'

export enum AssignmentState {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  NO_CAPACITY = 'NO_CAPACITY',
  CARRIER_BLACKLISTED = 'CARRIER_BLACKLISTED',
  AWAITING_MATCHING_CONFIRMATION = 'AWAITING_MATCHING_CONFIRMATION'
}

export enum AssignmentType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC'
}

export enum AcceptanceType {
  MANUALLY_ACCEPTED = 'MANUALLY_ACCEPTED',
  AUTO_ACCEPTED = 'AUTO_ACCEPTED'
}

export enum CompanyType {
  CARRIER = 'CARRIER',
  CHARTERING_OFFICE = 'CHARTERING_OFFICE'
}

export interface Carrier {
  companyId: string
  subsidiaryIds: number[]
  isDenylisted: boolean
  mothershipId: number
  name: string
}

export interface CPSCarrierContact {
  id: string
  carrierId: string
  email: string
  firstName: string
  lastName: string
  mothershipId: string
}

export interface CarrierContact {
  companyId: string
  contactId: string
  email: string
  firstName: string
  lastName: string
  mothershipId: string
}

export interface Creator {
  id: number
  name: string
}

export interface Owner {
  id: number
  name: string
}

export interface Assignment {
  id: string
  assignmentType: AssignmentType
  baseCost: number
  carrier: Carrier
  carrierContact: CarrierContact
  state: AssignmentState
  owner: Owner
  validUntil: string
  created: string
  createdBy: Creator
  marginPercentage: number
  transportoffer: unknown
}

export interface AssignmentsResponse {
  done: Assignment[]
  planned: Assignment[]
}

export interface Company {
  companyId: string
  mothershipId: number
  name: string
  isDenylisted: boolean
  type: CompanyType
}

export interface ShipmentAssignment {
  id: string
  assignmentType: AssignmentType
  acceptanceType: AcceptanceType
  baseCost: number
  baseConvertedCost: number
  currency: Currency
  company: Company
  owner: Owner
  createdBy: Creator
  state: AssignmentState
  validUntil: string
  created: string
}

export interface PlannedShipmentAssignment {
  priority: number
  baseCost: number
  baseConvertedCost: number
  currency: Currency
  assignmentType: AssignmentType
  company: Company
  validUntil?: string
  share?: number
  initial_capacity_day_2: number
  initial_capacity_day_1: number
  initial_capacity_day_3: number
  initial_capacity_day_4: number
  initial_capacity_day_5: number
  initial_capacity_day_6: number
  initial_capacity_day_7: number
  owner: Owner
  state: AssignmentState
}

export interface ShipmentAssignmentsResponse {
  done: ShipmentAssignment[]
  planned: PlannedShipmentAssignment[]
}

export interface Company {
  companyId: string
  mothershipId: number
  name: string
  type: CompanyType
  isDenylisted: boolean | null
}

export interface InternalAssignment {
  id: string | number // TODO: remove number when we won't have mothership
  isPlanned: boolean
  acceptanceType: AcceptanceType
  baseCost: number
  company: Company
  owner: Owner
  state: AssignmentState
  validUntil: string
  marginPercentage: number
  assignmentType?: AssignmentType
  createdBy?: Creator
  isBackfilled: boolean
  created: string
  currency: Currency
}

export const mapShipmentAssignments = (
  assignments: ShipmentAssignment[],
  basePrice: number
): InternalAssignment[] => {
  return assignments.map(x => {
    return {
      id: x.id,
      acceptanceType: x.acceptanceType,
      assignmentType: x.assignmentType,
      createdBy: x.createdBy,
      baseCost: x.baseCost,
      company: x.company,
      marginPercentage: (100 * (basePrice - x.baseConvertedCost)) / basePrice,
      owner: { id: x.owner?.id, name: x.owner?.name },
      state: x.state,
      validUntil: x.validUntil,
      isBackfilled: false,
      isPlanned: false,
      created: x.created,
      currency: x.currency
    }
  })
}

export const mapPlannedShipmentAssignments = (
  assignments: PlannedShipmentAssignment[],
  basePrice: number
): InternalAssignment[] => {
  return assignments.map(x => {
    return {
      id: uuidv4(),
      acceptanceType: AcceptanceType.MANUALLY_ACCEPTED,
      assignmentType: x.assignmentType,
      baseCost: x.baseCost,
      currency: x.currency,
      company: x.company,
      marginPercentage: (100 * (basePrice - x.baseConvertedCost)) / basePrice,
      owner: { id: x.owner?.id, name: x.owner?.name },
      state: x.state,
      validUntil: x.validUntil,
      isBackfilled: false,
      isPlanned: true,
      created: null
    }
  })
}
