






































































import CarrierContactDropdownField from '@/modules/ordering/components/shared/CarrierContactDropdownField.vue'
import isEmpty from 'lodash/isEmpty'
import { createCarrierAssignmentToBeDepreciated } from '@/services/order-assignment-service'
import orderingStoreMappers from '@/modules/ordering/store/mappers'
import { computed } from '@vue/composition-api'
import useStore from '@/compositions/useStore'

import { useCarrierForm } from '../../../../compositions'

export default {
  components: {
    CarrierContactDropdownField
  },
  props: {
    orderId: { type: Number, required: true },
    idForStaff: { type: String, required: true }
  },
  setup() {
    const store = useStore()

    const shouldUseNewEndpoints = computed(
      () => store.getters['ordering/containsShipmentsAndIsShipperPilot']
    )

    const { addCarrierAssignment, getCarrierById } = useCarrierForm()

    return {
      shouldUseNewEndpoints,
      getCarrierById,
      addCarrierAssignment,
      showDialog: false,
      inProgressRequest: false,
      carrierCompany: null,
      carrierContact: null,
      validity: null,
      baseCost: null,
      assignmentId: null
    }
  },
  computed: {
    ...orderingStoreMappers.mapState(['shipmentData'])
  },
  created() {
    this.validityValidators = [
      {
        isInvalid: value => isEmpty(value),
        errorMessage: this.$t(
          'ordering/dispatching-assignment-create-carrier-assignment-dialog-validity-error'
        )
      }
    ]
  },
  methods: {
    show({ carrierCompany, carrierContact, baseCost, validity }) {
      return new Promise(resolve => {
        this.resolve = resolve
        this.carrierCompany = carrierCompany
        this.carrierContact = carrierContact
        this.baseCost = baseCost
        this.validity = validity
        this.showDialog = true
      })
    },
    async handleConfirmEvent() {
      const { hasErrors: formErrors } = this.$refs.form.submit()
      if (formErrors) return
      try {
        this.inProgressRequest = true
        const payload = {
          orderId: this.orderId,
          carrierId: this.carrierCompany.id,
          carrierContactId: this.carrierContact.id,
          baseCost: this.baseCost,
          validity: this.validity
        }
        this.assignmentId = this.shouldUseNewEndpoints
          ? await this.addCarrierAssignment({
              carrier: await this.getCarrierById(this.carrierCompany.id),
              contact: this.carrierContact,
              orderId: this.orderId,
              baseCost: this.baseCost,
              validityHours: this.validity
            })
          : await createCarrierAssignmentToBeDepreciated(payload)

        await this.$nextTick() // otherwise assignmentId won't be passed as a prop for the palletExchangeCommitmentForm
        this.showDialog = false
        this.resolve && this.resolve('OK')
        this.resolve = null
      } finally {
        this.inProgressRequest = false
      }
    },
    handleCancelEvent() {
      this.showDialog = false
      this.resolve && this.resolve('CANCEL')
      this.resolve = null
    }
  }
}
