<template>
  <DropdownField
    v-bind="$attrs"
    :value="selected"
    :options="options"
    optionLabel="label"
    optionKey="value"
    searchable
    v-on="listeners"
  />
</template>

<script>
import { usePalletExchangeAddresses } from '../../shared/pallet-exchange'

export default {
  props: {
    value: { type: Number, required: false, default: null }
  },
  setup() {
    return { addresses: usePalletExchangeAddresses() }
  },
  computed: {
    options() {
      return this.addresses.map(address => ({
        value: address.id,
        label: address.formattedAddress
      }))
    },
    listeners() {
      return {
        ...this.$listeners,
        input: option => {
          this.$emit('input', option?.value)
        },
        select: option => {
          this.$emit('select', option?.value)
        }
      }
    },
    selected() {
      return this.options.find(option => option.value == this.value)
    }
  }
}
</script>
