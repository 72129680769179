<template>
  <Form ref="form" :disabled="disabled">
    <div class="plankton-grid">
      <div class="plankton-cell" span="5">
        <RadioButton
          :id="PALLET_EXCHANGE_TYPES.NOT_REQUIRED"
          :option="{
            value: type,
            label: PALLET_EXCHANGE_NAMES[PALLET_EXCHANGE_TYPES.NOT_REQUIRED],
            disabled
          }"
          :value="PALLET_EXCHANGE_TYPES.NOT_REQUIRED"
          @change="$emit('update:type', $event)"
        />
      </div>
      <div class="plankton-cell" span="7">
        <RadioButton
          :id="PALLET_EXCHANGE_TYPES.NOT_FEASIBLE_FOR_CARRIER"
          :value="PALLET_EXCHANGE_TYPES.NOT_FEASIBLE_FOR_CARRIER"
          :option="{
            value: type,
            label: PALLET_EXCHANGE_NAMES[PALLET_EXCHANGE_TYPES.NOT_FEASIBLE_FOR_CARRIER],
            disabled
          }"
          @change="$emit('update:type', $event)"
        />
      </div>
      <div class="plankton-cell" span="3">
        <RadioButton
          :id="PALLET_EXCHANGE_TYPES.DPL_STANDORT"
          :value="PALLET_EXCHANGE_TYPES.DPL_STANDORT"
          :option="{
            value: type,
            label: PALLET_EXCHANGE_NAMES[PALLET_EXCHANGE_TYPES.DPL_STANDORT],
            disabled
          }"
          @change="$emit('update:type', $event)"
        />
      </div>
      <div class="plankton-cell" style="display: flex" span="8">
        <PalletExchangeAddressDropdown
          style="margin-top: -30px"
          class="pallet-exchange-addresses"
          :value="addressId"
          :disabled="!addressListEnabled || disabled"
          @input="$emit('update:addressId', $event)"
        />
      </div>
    </div>
    <div class="plankton-grid" style="margin-top: -10px">
      <div class="plankton-cell" span="5">
        <RadioButton
          :id="PALLET_EXCHANGE_TYPES.REGULAR_PALLET_EXCHANGE"
          :value="PALLET_EXCHANGE_TYPES.REGULAR_PALLET_EXCHANGE"
          :option="{
            value: type,
            label: PALLET_EXCHANGE_NAMES[PALLET_EXCHANGE_TYPES.REGULAR_PALLET_EXCHANGE],
            disabled
          }"
          @change="$emit('update:type', $event)"
        />
      </div>
      <div class="plankton-cell" span="7">
        <RadioButton
          :id="PALLET_EXCHANGE_TYPES.DPL_VIRTUAL"
          :value="PALLET_EXCHANGE_TYPES.DPL_VIRTUAL"
          :option="{
            value: type,
            label: PALLET_EXCHANGE_NAMES[PALLET_EXCHANGE_TYPES.DPL_VIRTUAL],
            disabled
          }"
          @change="$emit('update:type', $event)"
        />
      </div>
    </div>
  </Form>
</template>

<script>
import { RadioButton } from '@sennder/plankton'
import PalletExchangeAddressDropdown from './PalletExchangeAddressDropdown'
import {
  PALLET_EXCHANGE_NAMES,
  PALLET_EXCHANGE_TYPES
} from '@/modules/ordering/constants'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    RadioButton,
    PalletExchangeAddressDropdown
  },
  props: {
    disabled: { type: Boolean, required: true },
    type: { type: String, required: false, default: '' },
    addressId: { type: Number, required: false, default: null }
  },
  computed: {
    addressListEnabled() {
      return this.type === PALLET_EXCHANGE_TYPES.DPL_STANDORT
    },
    PALLET_EXCHANGE_TYPES() {
      return PALLET_EXCHANGE_TYPES
    },
    PALLET_EXCHANGE_NAMES() {
      return PALLET_EXCHANGE_NAMES
    }
  },
  methods: {
    submit() {
      return { hasErrors: false }
    }
  }
})
</script>

<style scoped>
.pallet-exchange-addresses {
  margin-top: 20px;
}
</style>
