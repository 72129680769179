import { ActionContext, ActionTree, CommitOptions } from 'vuex'

import { RootState } from '@/models/store'
import * as orderingService from '@/services/ordering-service'

import { OrderStop } from '../models'
import { assignmentsService, charteringOfficesClient } from '../services'

import { GettersType } from './getters'
import { IAssignmentsState } from './state'
import { MutationsType } from './mutations'

// TODO: define root state
type AssignmentsActionContext<S = IAssignmentsState> = Omit<
  ActionContext<S, RootState>,
  'getters' | 'commit' | 'dispatch'
> & {
  commit<K extends keyof MutationsType, P extends Parameters<MutationsType[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<MutationsType[K]>
} & {
  dispatch<K extends keyof ActionsType>(
    key: K,
    payload?: Parameters<ActionsType[K]>[1],
    options?: ActionsType
  ): ReturnType<ActionsType[K]>
} & {
  getters: {
    [K in keyof GettersType]: ReturnType<GettersType[K]>
  }
}

type PartialActionContextOfAssignmentsState = Partial<AssignmentsActionContext>

export type FetchAssignmentsAction = (
  { getters, commit, rootState }: PartialActionContextOfAssignmentsState,
  data: { fetchOldAssignments: boolean }
) => Promise<void>
export const fetchAssignments: FetchAssignmentsAction = async (
  { getters, commit, rootState },
  { fetchOldAssignments }
) => {
  const shipmentId = getters['shipmentId']

  const assignments = await assignmentsService.getAssignments({
    orderId: fetchOldAssignments ? rootState.ordering.order.id : null,
    shipmentId,
    basePrice: rootState.ordering.order.basePrice
  })

  commit('setAssignments', assignments)
}

export type CreateCharteringAssignmentAction = (
  { getters, dispatch }: PartialActionContextOfAssignmentsState,
  {
    charteringOfficeId,
    baseCost
  }: {
    charteringOfficeId: string
    baseCost: number
  }
) => Promise<void>
export const createCharteringAssignment: CreateCharteringAssignmentAction = async (
  { getters, dispatch },
  { charteringOfficeId, baseCost }
) => {
  const shipmentId = getters['shipmentId']

  await assignmentsService.createCharteringAssignment(
    shipmentId,
    charteringOfficeId,
    baseCost
  )

  await dispatch('fetchAssignments')
}

export type CreateCarrierAssignmentAction = (
  { getters, dispatch }: PartialActionContextOfAssignmentsState,
  {
    carrierId,
    contactId,
    orderId,
    baseCost,
    validityHours,
    subsidiaryId
  }: {
    carrierId: string
    contactId: string
    orderId: number
    baseCost: number
    validityHours: number
    subsidiaryId?: number
  }
) => Promise<void>
export const createCarrierAssignment: CreateCarrierAssignmentAction = async (
  { getters, dispatch },
  { carrierId, contactId, baseCost, validityHours, subsidiaryId, orderId }
) => {
  const shipmentId = getters['shipmentId']

  await assignmentsService.createCarrierAssignment({
    carrierId,
    contactId,
    orderId,
    shipmentId,
    baseCost,
    validityHours,
    subsidiaryId
  })

  await dispatch('fetchAssignments')
}

export type FetchCarriersKpiAction = (
  { getters, commit }: PartialActionContextOfAssignmentsState,
  {
    orderId,
    customerId,
    orderStops
  }: {
    orderId: number
    customerId: number
    orderStops: OrderStop[]
  }
) => Promise<void>
export const fetchCarriersKpi: FetchCarriersKpiAction = async (
  { getters, commit },
  { orderId, customerId, orderStops }
) => {
  const carrierMothershipIds = getters.carrierMothershipIds
  const originCompanyAddressId = orderStops[0].warehouseAddress.companyAddressId
  const destinationCompanyAddressId =
    orderStops[orderStops.length - 1].warehouseAddress.companyAddressId
  if (
    carrierMothershipIds &&
    carrierMothershipIds.length > 0 &&
    originCompanyAddressId &&
    destinationCompanyAddressId
  ) {
    const carriersKpi = await orderingService.fetchCarriersKpiPost(
      orderId,
      carrierMothershipIds,
      customerId,
      originCompanyAddressId,
      destinationCompanyAddressId
    )
    commit('setCarriersKpi', carriersKpi)
  } else {
    commit('setCarriersKpi', null)
  }
}

export type FetchCharteringOfficesAction = ({
  commit
}: PartialActionContextOfAssignmentsState) => Promise<void>
export const fetchCharteringOffices: FetchCharteringOfficesAction = async ({
  commit
}) => {
  try {
    const charteringOffices = await charteringOfficesClient.getCharteringOffices()

    commit('setCharteringOffices', charteringOffices)
  } catch (exception) {
    commit('setCharteringOffices', [])
  }
}

export type FetchCarrierTrackingRateAction = (
  { commit, state }: PartialActionContextOfAssignmentsState,
  data: {
    carrierId: number
    fetchAgain: boolean
  }
) => Promise<number>
export const fetchCarrierTrackingRate: FetchCarrierTrackingRateAction = async (
  { commit, state },
  { carrierId, fetchAgain }
) => {
  // With fetchAgain set to false, we use the value saved in the store. Otherwise the request would be made
  // every time the assignments are updated in the OrderingRoot page. We want to avoid unecessary requests as
  // it is an heavy one.
  if (!fetchAgain && state.carriersTrackingRate[carrierId]) {
    return state.carriersTrackingRate[carrierId]
  }
  const { rate } = await orderingService.fetchCarrierTrackingRate(carrierId)
  commit('setCarrierTrackingRate', { carrierId, rate })
  return rate
}

export type ActionsType = {
  fetchAssignments: FetchAssignmentsAction
  createCarrierAssignment: CreateCarrierAssignmentAction
  createCharteringAssignment: CreateCharteringAssignmentAction
  fetchCarriersKpi: FetchCarriersKpiAction
  fetchCharteringOffices: FetchCharteringOfficesAction
  fetchCarrierTrackingRate: FetchCarrierTrackingRateAction
}

export const actions: ActionTree<IAssignmentsState, never> & ActionsType = {
  fetchAssignments,
  createCarrierAssignment,
  createCharteringAssignment,
  fetchCarriersKpi,
  fetchCharteringOffices,
  fetchCarrierTrackingRate
}
