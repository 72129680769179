import { Currency } from '@/modules/common'
import {
  AcceptanceType,
  AssignmentState,
  AssignmentType,
  CompanyType,
  InternalAssignment
} from './assignments'

interface MothershipCarrier {
  id: number
  name: string
}

interface MothershipCarrierContact {
  name: string
  email: string
  phone: string
}

interface MothershipCreator {
  id: number
  name: string
}

export interface MotershipOwner {
  id: number
  name: string
}

export interface MothershipAssignment {
  assignmentType: AssignmentType
  baseCost: number
  carrier: MothershipCarrier
  carrierContact: MothershipCarrierContact
  created: string
  createdBy: MothershipCreator
  id: number | string
  marginPercentage: number
  owner: MotershipOwner
  state: AssignmentState
  validUntil: string
  isBackfill: boolean
}

export interface MothershipAssignmentsResponse {
  done: MothershipAssignment[]
  planned: MothershipAssignment[]
}

export const mapMothershipAssignment = (
  assignments: MothershipAssignment[]
): InternalAssignment[] => {
  return assignments.map(x => ({
    id: x.id,
    acceptanceType: AcceptanceType.MANUALLY_ACCEPTED,
    assignmentType: x.assignmentType,
    baseCost: x.baseCost,
    company: {
      companyId: null,
      mothershipId: x.carrier.id,
      name: x.carrier.name,
      isDenylisted: false,
      type: CompanyType.CARRIER
    },
    createdBy: x.createdBy,
    marginPercentage: x.marginPercentage,
    owner: x.owner,
    state: x.state,
    validUntil: x.validUntil,
    isBackfilled: x.isBackfill,
    isPlanned: false,
    created: x.created,
    currency: Currency.EUR
  }))
}

export const mapPlannedMothershipAssignment = (
  assignments: MothershipAssignment[]
): InternalAssignment[] => {
  return assignments.map(x => ({
    id: x.id,
    acceptanceType: AcceptanceType.MANUALLY_ACCEPTED,
    assignmentType: x.assignmentType,
    createdBy: x.createdBy,
    baseCost: x.baseCost,
    company: {
      companyId: null,
      mothershipId: x.carrier.id,
      name: x.carrier.name,
      type: CompanyType.CARRIER,
      isDenylisted: false
    },
    marginPercentage: x.marginPercentage,
    owner: x.owner,
    state: x.state,
    validUntil: x.validUntil,
    isBackfilled: false,
    isPlanned: true,
    created: x.created,
    currency: Currency.EUR
  }))
}
