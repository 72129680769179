







































































































































































































import { PropType, computed, defineComponent } from '@vue/composition-api'
import uuidv4 from 'uuid/v4'

import { humanizeIsoDateTimeWithDots } from '@sennder/plankton'

import { formatMoneyWithDashForNull } from '@/global-setup/filters'
import useFeatureFlag from '@/compositions/useFeatureFlag'

import {
  AllocationList,
  AllocationListPlaceholder
} from '../../components/allocation-list'
import { ListCell, ListCellCarrier, ListCellQuality } from '../../components/list-cells'

import { useAssignments } from '../../compositions'
import { ASSIGNMENTS_FEATURE_FLAGS, CARRIER_ASSIGNMENT_STATES } from '../../constants'

import { Bid, Order, OrderStop, SpotCosting } from '../../models'

import { ListCellStatusPartnerManager } from './list-cells'

export default defineComponent({
  name: 'CarrierManagerAssignments',
  components: {
    AllocationList,
    AllocationListPlaceholder,
    ListCell,
    ListCellCarrier,
    ListCellQuality,
    ListCellStatusPartnerManager
  },
  props: {
    order: { type: Object as PropType<Order>, required: true },
    orderStops: { type: Array as PropType<OrderStop[]>, required: true },
    hasBeenCarrierLocked: { type: Boolean, required: true },
    spotCosting: { type: Object as PropType<SpotCosting>, required: true },
    offerList: { type: Array as PropType<Bid[]>, required: true }
  },
  setup(props) {
    const { isActive } = useFeatureFlag()
    const {
      showView,
      errorWhileFetchingAssignments,
      assignments,
      pendingAssignment,
      assignmentTypes,
      carrierKpi,
      refetch
    } = useAssignments(props)

    const isTargetDeviationEnabled = computed(
      () =>
        isActive(ASSIGNMENTS_FEATURE_FLAGS.ENABLE_ORDER_DETAILS_TARGET_DEVIATION).value
    )

    const carrierAllocationAssignments = computed(() => {
      const allocations = assignments.value.map(assignment => ({
        assignmentType: assignment.assignmentType,
        type: 'ASSIGNMENT',
        //Automatic assignment created by cascading system doesn't have ids
        //so we generate one for the key loop used by vuejs
        id: assignment.id || uuidv4(),
        carrierId: assignment.company.companyId || assignment.company.mothershipId,
        carrierName: assignment.company.name,
        isDenylisted: assignment.company.isDenylisted,
        isOrcasRequest: false,
        price: assignment.baseCost,
        owner: assignment.owner,
        state: assignment.state,
        created: assignment.created,
        marginPercentage: assignment.marginPercentage,
        rejectionReason: null,
        rejectionDescription: null,
        modified: null,
        validUntil: assignment.validUntil,
        carrierKpis: carrierKpi.value(assignment.company.mothershipId),
        currency: assignment.currency
      }))

      return allocations
    })

    const marginPercentageClass = allocation => {
      if (Math.round(allocation.marginPercentage) >= 5) {
        return 'margin-ok'
      }
      if (Math.round(allocation.marginPercentage) > 0) {
        return 'margin-warning'
      }
      if (Math.round(allocation.marginPercentage) <= 0) {
        return 'margin-error'
      }

      return 'margin-unknown'
    }

    const shouldShowAssignmentInfoTooltip = allocation => {
      if (allocation.state !== CARRIER_ASSIGNMENT_STATES.PENDING || !allocation.created) {
        return false
      }

      //check if their is another allocation pending after this one
      return !assignments.value.some(
        assignment => assignment.state === CARRIER_ASSIGNMENT_STATES.NOT_ASSIGNED
      )
    }
    const targetDeviationPercentage = allocation => {
      if (allocation.price && props.spotCosting.suggestedBaseCost) {
        return (
          ((props.spotCosting.suggestedBaseCost - allocation.price) /
            props.spotCosting.suggestedBaseCost) *
          100
        )
      }
      return null
    }
    const targetDeviationPercentageClass = allocation => {
      const allocationPercentage = targetDeviationPercentage(allocation)
      if (allocationPercentage) {
        if (Math.round(allocationPercentage) >= 0) {
          return 'target-deviation-ok'
        }
        if (Math.round(allocationPercentage) < 0) {
          return 'target-deviation-error'
        }
      }
      return ''
    }

    return {
      showView,
      errorWhileFetchingAssignments,
      carrierAllocationAssignments,
      pendingAssignment,
      assignmentTypes,

      marginPercentageClass,
      shouldShowAssignmentInfoTooltip,
      targetDeviationPercentage,
      targetDeviationPercentageClass,

      humanizeIsoDateTimeWithDots,
      formatMoneyWithDashForNull,

      isTargetDeviationEnabled,

      refetch
    }
  }
})
