<template>
  <div class="allocation-container">
    <h4 class="list-title subsection-title" data-test="allocation-title">
      <slot name="title"></slot>
    </h4>
    <div>
      <slot name="alert"></slot>
    </div>
    <div class="list__table" data-test="am-offer-table">
      <div class="plankton-grid list__table-header table-header">
        <slot name="header"></slot>
      </div>
      <div v-if="carrierAllocations.length > 0">
        <div
          v-for="allocation in sortedCarrierAllocations"
          :key="allocation.type + allocation.id"
          :data-offer-id="allocation.id"
          class="list__table-row"
          data-test="carrier-allocation-list-item"
        >
          <slot :allocation="allocation" name="row"></slot>
        </div>
      </div>
      <div v-else class="list__table--placeholder" data-test="no-content-placeholder">
        <h5 class="page-header">
          <slot name="no-content"></slot>
        </h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    carrierAllocations: { type: Array, required: true },
    sortByCreatedDate: { type: Boolean, default: true }
  },
  computed: {
    sortedCarrierAllocations() {
      if (this.sortByCreatedDate) {
        return [...this.carrierAllocations].sort((offer1, offer2) => {
          const date1 = new Date(offer1.created)
          const date2 = new Date(offer2.created)
          return Math.sign(date2.getTime() - date1.getTime())
        })
      }
      return this.carrierAllocations
    }
  }
}
</script>

<style scoped lang="scss">
.allocation-container {
  min-height: 264px;

  .list-title {
    margin-bottom: 22px;
  }

  .list__table {
    .list__table-header {
      box-sizing: border-box;
      border-bottom: 1px solid $color-layout-divider;
      padding-bottom: 8px;
    }

    .list__table-row {
      padding: (2 * $grid-base) 0;
      box-sizing: border-box;
      border-bottom: 1px solid $color-layout-divider;

      .button-wrapper {
        margin-left: auto;
      }

      .accept-offer-button {
        margin-right: 8px;
      }
    }

    &--placeholder {
      h5 {
        padding-top: 85px;
        color: $color-neutral-main;
        max-width: 600px;
        margin: 26px auto 0;
      }
    }
  }
}
</style>
